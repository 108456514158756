<template>
  <div>
    <div v-if="displayLoader">
      <div class="text-center">
        <div>
          <b-spinner label="Spinning"></b-spinner>
        </div>
        <h2 class="pt-5">Loading report. It may take a few seconds.</h2>
      </div>
    </div>
    <NotFoundPage v-else-if="displayNotFound" title="Report not found" />
    <NotFoundPage
      v-else-if="isHidePrices"
      title="Report is not available"
      subtitle="Report is not available for current user due to system settings. Please contact our administrator for elaboration"
    />
    <div v-else>
      <AssignmentStripe />
      <div class="container">
        <div class="report-container">
          <ReportHeader />
          <div class="optimization-wrap">
            <ReportSummary />
            <BeforeOptimizationTable />
            <AfterOptimizationTable />
          </div>
        </div>

        <div class="assignment-footer d-flex justify-content-between align-items-center">
          <div class="footer-btn ml-auto d-flex align-items-center">
            <AssignmentActions origin="report" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, type ComputedRef, onMounted } from 'vue'
import { useStore } from 'vuex'
import AssignmentActions from '@/views/Components/Assignment/Actions/AssignmentActions.vue'
import AfterOptimizationTable from '@/views/Assignments/Report/After.vue'
import BeforeOptimizationTable from '@/views/Assignments/Report/Before.vue'
import ReportSummary from '@/views/Assignments/Report/Summary/Summary.vue'
import { onBeforeRouteLeave } from 'vue-router'
import { LoadingStatus } from '@/const/LoadingStatus'
import { none } from '@/services/Helpers'
import type { Assignment } from '@/types/Models/Assignment'
import AssignmentStripe from '@/views/Components/Assignment/Stripe/AssignmentStripe.vue'
import ReportHeader from '@/views/Assignments/Report/Header.vue'
import NotFoundPage from '@/views/Components/NotFoundPage/NotFound.vue'

export default defineComponent({
  name: 'OptimizationReport',
  components: {
    NotFoundPage,
    ReportHeader,
    AssignmentStripe,
    ReportSummary,
    AfterOptimizationTable,
    BeforeOptimizationTable,
    AssignmentActions,
  },
  setup() {
    const store = useStore()

    const isStaff: ComputedRef<boolean> = computed(() => store.getters['account/isStaff'])
    const assignment: ComputedRef<Assignment> = computed(() => store.getters['assignment/assignmentSummary/get'])
    const loadingStatus: ComputedRef<LoadingStatus> = computed(
      () => store.getters['assignment/optimizationReport/loadingStatus'],
    )
    const displayLoader: ComputedRef<boolean> = computed(() =>
      [LoadingStatus.PENDING, LoadingStatus.LOADING].includes(loadingStatus.value),
    )
    const displayNotFound: ComputedRef<boolean> = computed(() => loadingStatus.value === LoadingStatus.FAILURE)
    const isHidePrices: ComputedRef<boolean> = computed(() => !isStaff.value && assignment.value?.company?.hidePrice)

    onMounted(() => {
      store.dispatch('assignment/optimizationReport/fetch', assignment.value.id).catch(none)
    })

    onBeforeRouteLeave((_to, _from, next) => {
      store.dispatch('assignment/optimizationReport/reset')
      next()
    })

    return {
      isHidePrices,
      displayLoader,
      displayNotFound,
    }
  },
})
</script>

<style lang="scss" scoped>
.report-container {
  margin-bottom: 8rem;
}

.optimization-report-option {
  margin-top: 15px;
}

.optimization-wrap {
  padding: 28px 24px;
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.optimization-wrap .logo {
  width: 80px;
  height: 80px;
  margin-left: 15px;
  z-index: 0;
}

.optimization-action .btn {
  width: auto;
}

.optimization-action .to-do-button {
  max-width: 100%;
}
</style>
