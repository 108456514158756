<template>
  <CustomSelect
    v-if="isStaff"
    class="col-12"
    placeholder="Choose client"
    :selected="companyId"
    :options="customers"
    :selectable="(option: CompanyWithLabel) => option.isActive"
    :filter="clientFilter"
    @selected="setCustomer"
  >
    <template #custom_option="{ option }: { option: CompanyWithLabel }">
      <div :class="'option-name item-' + option.status">
        <span class="status" />
        <span class="name">{{ option.label }}</span>
        (Name: {{ `${option.contactName} ${option.contactLastName}` }}; Client number: {{ option.clientNumber }}; Email:
        {{ option.email }}; Phone: {{ option.phoneNumber }})
      </div>
    </template>
  </CustomSelect>
</template>

<script lang="ts">
import type { CompanyWithLabel } from '@/types/Models/Company'
import { defineComponent, ref, computed, onBeforeMount, type Ref, type ComputedRef } from 'vue'
import { useStore } from 'vuex'
import CustomSelect from '@/views/Components/Elements/Form/CustomSelect.vue'

export default defineComponent({
  name: 'ClientSelect',
  components: {
    CustomSelect,
  },
  props: {
    tableName: {
      type: String,
      default: null,
    },
  },
  emits: ['changeClient'],
  setup(_, { emit }) {
    const store = useStore()

    const companyId: Ref<string | undefined> = ref()
    const customers: ComputedRef<CompanyWithLabel[]> = computed(() => store.getters['customers/all'])
    const isStaff: ComputedRef<boolean> = computed(() => store.getters['account/isStaff'])

    const clientFilter = (option: CompanyWithLabel, _: any, search: string) => {
      const searchString = search.toLowerCase()
      const text = `${option?.label} ${option?.name} ${option?.id} ${option?.contactName} ${option?.contactLastName} ${option?.email} ${option?.phoneNumber}`
      return text.toLowerCase().indexOf(searchString) > -1
    }

    const setCustomer = (id: string) => {
      companyId.value = id
      emit('changeClient', id)
    }

    onBeforeMount(() => {
      if (customers.value.length === 0) {
        store.dispatch('customers/fetchAll')
      }
    })

    return {
      companyId,
      customers,
      isStaff,
      clientFilter,
      setCustomer,
    }
  },
})
</script>

<style lang="scss" scoped>
.name {
  font-weight: bold;
}
.option-name {
  font-size: 14px;
}
</style>
