<template>
  <div>
    <easy-data-table
      ref="dataTable"
      v-model:server-options="serverOptions"
      table-class-name="vue3-easy-data-table table-shadow"
      :server-items-length="serverItemsLength"
      :loading="loading"
      :headers="headers"
      :items="items"
      :prevent-context-menu-row="false"
      :fixed-header="false"
      body-row-class-name="no-wrap"
      no-hover
      alternating
      hide-footer
      @update:server-options="updateServerOptions"
    >
      <template #loading>
        <div style="text-align: center">Loading...</div>
      </template>
      <template #no-data>
        <div style="text-align: center">No data available</div>
      </template>
      <template #item="{ column, item }: { column: string; item: PackingSlipItem }">
        <span v-if="column === 'createdAt'">
          <b>{{ formattedDate(item.createdAt, 'YYYY-MM-DD') }}</b>
          <br />
          <span class="text-muted">{{ formattedDate(item.createdAt, 'HH:mm') }}</span>
        </span>
        <span v-if="column === 'sender'">
          <b>{{ item.sender }}</b>
        </span>
        <span v-if="column === 'recipient'">
          <b>{{ item.recipient }}</b>
        </span>
        <span v-if="column === 'shipmentNumber'">
          <span
            v-b-tooltip="{ title: getTrackingStatus(item.lastTrackingEventStatus), customClass: 'success' }"
            class="success"
          >
            <div class="d-flex table-input align-items-center pl-0 h-100">
              <span class="tooltip-text tracking">
                <a :href="getLinkToTrackingPage(item.shipmentNumber)" target="_blank">
                  {{ capitalize(item.shipmentNumber) }}
                </a>
              </span>
            </div>
          </span>
        </span>
        <span v-if="column === 'estimatedCost'">
          <div
            v-b-tooltip="{ title: 'In comparison to Postnord default pricing.' }"
            class="d-flex align-items-center saved-cost"
          >
            <span class="saved-cost__value">{{ item.totalPrice }} kr</span>
            <div v-if="item.percentSaved" ref="tooltipTrigger" class="saved-cost__percent">
              {{ formattedNumber(item.percentSaved) }}% Saved
            </div>
          </div>
        </span>
        <span v-if="column === 'moreActions'">
          <span v-b-tooltip="{ title: deleteButtonText(item), customClass: 'danger' }" class="mt-2 danger">
            <b-button
              class="file-btn delete-btn"
              variant="outline-light"
              :disabled="isShipped(item)"
              @click="removeItem(item.id)"
            >
              <b-icon icon="trash" class="btn-table-action" variant="danger" small />
            </b-button>
          </span>
        </span>
      </template>
    </easy-data-table>

    <TablePagination
      v-model:server-options-ref="serverOptions"
      v-model:data-table-ref="dataTable"
      :server-items-length="serverItemsLength"
    />

    <confirm
      id="confirmDelete"
      message="Are you sure you want to remove this item? "
      title="Remove Item From Packing Slip"
      :on-confirm="onDelete"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, watch, type Ref, type PropType, type ComputedRef } from 'vue'
import { getLinkToTrackingPage, getTrackingStatus } from '@/services/Tracking'
import { capitalize, formattedDate, formattedNumber } from '@/services/Helpers'
import { openModal } from '@/services/Modal'
import { useStore } from 'vuex'
import { SET_PACKING_SLIP } from '@/store/modules/packingSlip'
import { FAILED_ATTEMPT, INFO_RECEIVED } from '@/const/TrackingEventStatuses'
import { CREATED, FAILED, GENERATING, READY } from '@/const/PackingSlipEventTypes'
import { PACKING_SLIP_EDIT, PACKING_SLIP_PREVIEW, PACKING_SLIP } from '@/const/ModalTypes'
import TablePagination from '@/views/Components/Elements/Table/TablePagination.vue'
import Confirm from '@/views/Modals/Confirm.vue'
import BIcon from '@/views/Components/Elements/Icons/BIcon.vue'
import PackingSlipProxy from '@/proxies/PackingSlipProxy'
import EasyDataTable, { type ServerOptions } from 'vue3-easy-data-table'
import type { PackingSlip, PackingSlipItem, PackingSlipItemCollection } from '@/types/Models/PackingSlip'
import type { SelectOptions } from '@/types/Components/SelectOptions'

export default defineComponent({
  name: 'PackingSlipItemsTable',
  components: {
    EasyDataTable,
    TablePagination,
    Confirm,
    BIcon,
  },
  props: {
    packingSlip: {
      type: Object as PropType<PackingSlip>,
      required: true,
    },
    index: {
      type: String,
      default: '0',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['setTotals'],
  setup(props, { emit }) {
    const store = useStore()
    const dataTable = ref()
    const items: Ref<PackingSlipItem[]> = ref([])
    const error: Ref<boolean> = ref(false)
    const loading: Ref<boolean> = ref(false)
    const serverItemsLength: Ref<number> = ref(0)
    const serverOptions: Ref<ServerOptions> = ref({
      page: 1,
      rowsPerPage: 50,
    })

    const headers: ComputedRef<SelectOptions[]> = computed(() => [
      { text: 'Created', value: 'createdAt' },
      { text: 'Sender', value: 'sender' },
      { text: 'Recipient', value: 'recipient' },
      { text: 'Tracking Number', value: 'shipmentNumber' },
      { text: 'Shipping Cost', value: 'estimatedCost' },
      { text: 'Action', value: 'moreActions' },
    ])

    const loadFromServer = async () => {
      loading.value = true
      new PackingSlipProxy().getItems(props.packingSlip.id).then((response: PackingSlipItemCollection) => {
        items.value = response.data || []
        serverItemsLength.value = response.count || 0
        error.value = false
        loading.value = false
        emit('setTotals', {
          totalPackages: response.count,
          totalWeight: response.totalWeight,
        })
      })
    }

    const updateServerOptions = (newOptions: ServerOptions) => {
      serverOptions.value = newOptions
    }

    const listenToPackingSlipEvents = (packingSlip: PackingSlip) => {
      //we should return in a websockets default PackingSlip contract
      const stopListeningToPackingSlipEvents = () => {
        window.Echo.channel(`App.User.${store.getters['account/userId']}`)
          .stopListening(`.${packingSlip.id}.${GENERATING}`)
          .stopListening(`.${packingSlip.id}.${READY}`)
          .stopListening(`.${packingSlip.id}.${FAILED}`)
      }
      window.Echo.channel(`App.User.${store.getters['account/userId']}`)
        .listen(`.${packingSlip.id}.${GENERATING}`, (data: PackingSlip) => {
          store.commit(`packingSlip/${SET_PACKING_SLIP}`, data)
        })
        .listen(`.${packingSlip.id}.${READY}`, (data: PackingSlip) => {
          store.commit(`packingSlip/${SET_PACKING_SLIP}`, data)
          store.dispatch('packingSlip/getPackingSlips')
          stopListeningToPackingSlipEvents()
        })
        .listen(`.${packingSlip.id}.${FAILED}`, (data: PackingSlip) => {
          store.commit(`packingSlip/${SET_PACKING_SLIP}`, data)
          stopListeningToPackingSlipEvents()
        })
    }

    const onDelete = () => {
      const id = computed(() => store.getters['vueNextModal/modalProps']('confirmDelete')?.id)
      store.commit(`packingSlip/${SET_PACKING_SLIP}`, {
        id: props.packingSlip.id,
        downloadUrl: null,
        economyPreview: null,
        priorityPreview: null,
        state: CREATED,
      })
      listenToPackingSlipEvents(props.packingSlip)
      store.dispatch('packingSlip/removeItems', {
        id: props.packingSlip.id,
        items: [id],
      })
      store.dispatch('packingSlip/getPackingSlips')
      store.dispatch('vueNextModal/closeModal', PACKING_SLIP_PREVIEW)
      store.dispatch('vueNextModal/closeModal', PACKING_SLIP_EDIT)
      store.dispatch('vueNextModal/closeModal', PACKING_SLIP)
    }

    const removeItem = (id: string) => {
      openModal('confirmDelete', { id })
    }

    const isShipped = (row: PackingSlipItem) => {
      return row.lastTrackingEventStatus !== FAILED_ATTEMPT && row.lastTrackingEventStatus === INFO_RECEIVED
    }

    const deleteButtonText = (row: PackingSlipItem) => {
      return isShipped(row) ? "This item cannot be deleted because it's shipped" : ''
    }

    onMounted(loadFromServer)
    watch(serverOptions, loadFromServer, { deep: true })

    return {
      dataTable,
      items,
      error,
      loading,
      headers,
      serverOptions,
      serverItemsLength,
      updateServerOptions,
      removeItem,
      isShipped,
      onDelete,
      deleteButtonText,
      getTrackingStatus,
      getLinkToTrackingPage,

      formattedNumber,
      formattedDate,
      capitalize,
    }
  },
})
</script>

<style lang="scss" scoped>
.file-btn:disabled {
  opacity: 0.5;
}
.tooltip-text a {
  color: #000;
}
.saved-cost {
  display: flex;
  align-items: center;
  span {
    color: #08a762;
  }
  &__percent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    background: rgba(8, 167, 98, 0.15);
    border-radius: 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 144%;
    color: #08a762;
    padding: 0 5px;
    margin-left: 8px;
  }
}
</style>
