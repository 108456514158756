<template>
  <div class="sender-address-prices-header">
    <div class="row w-100">
      <div class="col-6 my-auto">
        <div class="assignment-number mr-4 d-flex align-items-center">
          <router-link :to="customersArea" class="d-flex align-items-center mr-4">
            <a class="back-to position-absolute">
              <mdicon name="ChevronLeft" />
            </a>
          </router-link>
          <span class="ml-1 sender-address-title">
            Prices for sender address of
            <b>{{ customer?.name || `${customer?.contactName} ${customer?.contactLastName}` }}</b> customer
          </span>
          <div class="d-flex flex-column badges">
            <b-badge v-if="selectedSenderAddress?.hasCustomPrices" variant="success" class="ml-3">
              Sender prices
            </b-badge>
            <b-badge
              v-if="selectedSenderAddress?.hasCustomPriorities"
              variant="warning"
              class="ml-3"
              :class="{ 'mt-1': selectedSenderAddress?.hasCustomPrices }"
            >
              Sender carriers
            </b-badge>
          </div>
        </div>
      </div>
      <div class="col-6 pr-0">
        <div class="d-flex justify-content-end">
          <div class="col-12 col-lg-9 col-xl-auto ml-4 pr-0">
            <div class="d-flex align-items-center mb-0">
              <label class="mt-0 mb-0 mr-3 font-weight-bold"> Sender Address: </label>
              <CustomSelect
                :selected="selectedSenderAddress?.id"
                :options="senderAddresses"
                :selectable="(option: Address) => option"
                placeholder="Choose sender address"
                @selected="changeSenderAddress"
              >
                <template #custom_option="{ option }: { option: Address }">
                  <div :class="'item-' + option.id">
                    <span class="status" />
                    <span class="name">{{ option.company }},</span>
                    {{ option.countryCode }}, {{ option.city }}, {{ option.addressLine }} {{ option.addressLine2 }}
                    <b-badge v-if="option.customNumbers?.length" variant="warning"> Customer Numbers </b-badge>
                    <b-badge v-if="option.isDefault" class="ml-1" variant="success"> Default Address </b-badge>
                    <b-badge v-if="option.hasCustomPrices" variant="success" class="ml-1"> Sender prices </b-badge>
                  </div>
                </template>
              </CustomSelect>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { Address } from '@/types/Models/Customer'
import type { BasePrice } from '@/types/Models/BasePrice'
import type { RouterLink } from '@/types/Components/Router'
import type { CompanyWithLabel } from '@/types/Models/Company'
import { none } from '@/services/Helpers'
import { defineComponent, ref, computed, watch, type Ref, type ComputedRef } from 'vue'
import { useStore } from 'vuex'
import { openModal } from '@/services/Modal'
import { onBeforeRouteLeave, useRoute } from 'vue-router'
import { STAFF_CUSTOMERS_LIST } from '@/types/router'
import { DEFAULT_LOADER } from '@/const/LoadersNames'
import CustomSelect from '@/views/Components/Elements/Form/CustomSelect.vue'

export default defineComponent({
  name: 'SenderAddressPricesActionMenu',
  components: { CustomSelect },
  setup() {
    const store = useStore()
    const route = useRoute()
    const customer: Ref<CompanyWithLabel | null> = ref(null)
    const selectedSenderAddress: Ref<Address | null> = ref(null)
    const defaultPrices: Ref<BasePrice[]> = ref([])

    const senderAddresses: ComputedRef<Address[]> = computed(() => store.getters['customers/senderAddresses/get'])
    const customers: ComputedRef<CompanyWithLabel[]> = computed(() => store.getters['customers/all'])
    const customersArea: RouterLink = { name: STAFF_CUSTOMERS_LIST }

    const changeSenderAddress = (val: string) => {
      store.dispatch('ui/loaderShow', { name: DEFAULT_LOADER })
      selectedSenderAddress.value = senderAddresses.value.find((el: Address) => el.id === val) || null
      store.dispatch('customers/senderAddresses/selectSenderAddress', {
        companyId: customer.value?.id,
        addressId: selectedSenderAddress.value?.id,
      })
      getPrices(val)
    }

    const getPrices = (id?: string) => {
      store
        .dispatch('prices/fetchDefault', id)
        .then((res: BasePrice[]) => {
          defaultPrices.value = res
          store
            .dispatch('prices/fetch', id)
            .then((result: BasePrice[]) => {
              if (result.length === 0) {
                store.commit('prices/SET_PRICES', defaultPrices.value)
              }
              store.dispatch('ui/loaderHide', { name: DEFAULT_LOADER })
            })
            .catch(() => {
              store.dispatch('ui/loaderHide', { name: DEFAULT_LOADER })
            })
        })
        .catch(none)
    }

    watch(senderAddresses, (val) => {
      if (val.length) {
        selectedSenderAddress.value = { ...val[0] }
        store
          .dispatch('customers/senderAddresses/selectSenderAddress', {
            companyId: route.params.id,
            addressId: selectedSenderAddress.value.id,
          })
          .catch(none)
        getPrices(selectedSenderAddress.value.id)
      }
    })

    watch(customers, (val) => {
      if (val.length) {
        customer.value = customers.value.find((el: CompanyWithLabel) => el.id === route.params.id) || null
      }
    })

    onBeforeRouteLeave((_to, _from, next) => {
      let changedPrices = store.getters['prices/changedPrices'].value
      if (changedPrices && changedPrices.length > 0) {
        openModal('confirmWrap', {
          title: 'There are unsaved changes',
          message: 'Are you sure you want to cancel?',
          onConfirm: () => {
            store.dispatch('prices/reset')
            next(true)
          },
          onCancel: () => {
            next(false)
          },
        })
      } else {
        next()
      }
    })

    return {
      customer,
      selectedSenderAddress,
      senderAddresses,
      customersArea,
      changeSenderAddress,
      getPrices,
    }
  },
})
</script>

<style lang="scss" scoped>
.sender-address-prices-header {
  width: 100%;
  display: flex;
  align-items: center;
  height: 70px;
  background: white;
  padding: 16px 13px;
  margin-bottom: -25px;
}
.sender-address-title {
  font-size: 15px;
}
.badges {
  font-size: 14px;
}
:deep(.vs__actions) {
  display: block;
  & > :not(.vs__open-indicator) {
    display: none;
  }
}
.custom-selectbox {
  min-width: 450px;
}
</style>
