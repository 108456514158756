<template>
  <modal-wrap
    :id="id"
    ref="createPackingSlip"
    :name="modalName"
    :hide-footer="false"
    header-class="packing-slip-items-header"
    body-class="packing-slip-items-modal"
    size="lg"
  >
    <template #modal-title> Create Packing Slip </template>
    <template #default>
      <div class="row mt-3">
        <div class="col-3">
          <date-picker
            v-model="date"
            format="yyyy-MM-dd"
            range
            multi-calendars
            placeholder="Filter by date"
            input-class="date-input"
            popup-class="date-drop"
            :clearable="true"
          >
          </date-picker>
        </div>
        <div class="col-3">
          <Search :hide-country="true" @search-triggered="search" />
        </div>
      </div>
      <div class="row mt-3">
        <ClientSelect v-if="isStaff" :table-name="tableName" @change-client="setClient" />
      </div>

      <div>
        <easy-data-table
          ref="dataTable"
          v-model:server-options="serverOptions"
          v-model:items-selected="checkedAssignments"
          table-class-name="vue3-easy-data-table table-shadow"
          body-row-class-name="no-wrap"
          :server-items-length="serverItemsLength"
          :loading="loading"
          :headers="headers"
          :items="items"
          :prevent-context-menu-row="false"
          :fixed-header="false"
          no-hover
          alternating
          hide-footer
          @update:server-options="updateServerOptions"
        >
          <template #loading>
            <div style="text-align: center">Loading...</div>
          </template>
          <template #no-data>
            <div style="text-align: center">No data available</div>
          </template>
          <template #item="{ column, item }: { column: string; item: Assignment }">
            <span v-if="column === 'createdAt'">
              <span
                ><b>{{ formattedDate(item.createdAt, 'YYYY-MM-DD') }}</b></span
              >
              <br />
              <span class="text-muted">{{ formattedDate(item.createdAt, 'HH:mm') }}</span>
            </span>
            <span v-else-if="column === 'updatedAt'">
              <span
                ><b>{{ toTimeFromNow(item.updatedAt) }}</b></span
              >
              <br />
            </span>
            <span v-else-if="column === 'reference'">
              <div class="d-flex flex-column">
                {{ item.reference }}
                <b-badge v-if="item.hasCustomPriorities" class="default-label mt-1" variant="success">
                  Custom priorities
                </b-badge>
                <b-badge v-if="item.hasCustomPrices" variant="success" class="mt-1"> Custom prices </b-badge>
              </div>
            </span>
            <span v-else-if="column === 'company.name'">
              {{
                item.company.name ? item.company.name : `${item.company.contactName} ${item.company.contactLastName}`
              }}
            </span>
            <span v-else-if="column === 'company.shortName'">
              {{ item.company.shortName }}
            </span>
            <span v-else-if="column === 'recipient'">
              {{ item.recipient?.country }}, {{ item.recipient?.postalCode }}, {{ item.recipient?.city }},
              {{ item.recipient?.addressLine }}
            </span>
            <span v-else-if="column === 'carriers'">
              <CarrierRowWithIcon v-if="item.item?.carrierHandle" :carrier="item.item?.carrierHandle" />
            </span>
            <span v-else>
              {{ column in item ? item[column as keyof Assignment] : '' }}
            </span>
          </template>
        </easy-data-table>

        <TablePagination
          v-model:server-options-ref="serverOptions"
          v-model:data-table-ref="dataTable"
          :server-items-length="serverItemsLength"
        />
      </div>
    </template>
    <template #modal-footer>
      <div class="row w-100 m-0">
        <div class="col-7 p-0 my-auto">
          <div class="row">
            <div class="col-3 package-info my-auto no-wrap">
              Total weight: <span>{{ totalWeight }}</span>
            </div>
            <div class="col-6 package-info my-auto no-wrap">
              Total packages: <span>{{ totalPackages }}</span>
            </div>
          </div>
        </div>
        <Continue :enable-continue-button="checkedAssignments.length > 0" />
      </div>
    </template>
  </modal-wrap>
</template>

<script lang="ts">
import type { Assignment } from '@/types/Models/Assignment'
import { defineComponent, ref, computed, watch, onMounted, type Ref, type ComputedRef } from 'vue'
import { useStore } from 'vuex'
import { openModal } from '@/services/Modal'
import { formattedDate, toTimeFromNow } from '@/services/Helpers'
import { TABLE_PACKING_SLIP_ASSIGNMENTS } from '@/const/TableNames'
import { CREATE_PACKING_SLIP } from '@/const/ModalTypes'
import { SET_COMPANY_ID, UPDATE_CHECKED_ASSIGNMENTS } from '@/store/modules/packingSlip'
import ModalWrap from '@/views/Modals/ModalWrap.vue'
import Continue from '@/views/Components/PackingSlip/Continue.vue'
import ClientSelect from '@/views/Components/PackingSlip/ClientSelect.vue'
import TablePagination from '@/views/Components/Elements/Table/TablePagination.vue'
import CarrierRowWithIcon from '@/views/Components/Assignment/CarrierRowWithIcon.vue'
import Search from '@/views/Components/PageParts/Search.vue'
import DatePicker from '@vuepic/vue-datepicker'
import EasyDataTable, { type ServerOptions } from 'vue3-easy-data-table'
import AssignmentProxy from '@/proxies/AssignmentProxy'
import moment from 'moment/moment'

export default defineComponent({
  name: 'CreatePackingSlipAssignmentsModal',
  components: {
    Search,
    Continue,
    ModalWrap,
    DatePicker,
    ClientSelect,
    TablePagination,
    CarrierRowWithIcon,
    EasyDataTable,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup() {
    const store = useStore()

    const items: Ref<Assignment[]> = ref([])
    const dataTable = ref(null)
    const loading: Ref<boolean> = ref(false)
    const query: Ref<string> = ref('')
    const date: Ref<string[]> = ref([])
    const serverItemsLength: Ref<number> = ref(0)
    const totalWeight: Ref<number> = ref(0)
    const totalPackages: Ref<number> = ref(0)
    const checkedAssignments: Ref<Assignment[]> = ref([])
    const modalName: string = CREATE_PACKING_SLIP
    const tableName: string = TABLE_PACKING_SLIP_ASSIGNMENTS
    const serverOptions: Ref<ServerOptions> = ref({
      page: 1,
      rowsPerPage: 10,
      sortBy: 'createdAt',
      sortType: 'desc',
    })

    const isStaff: ComputedRef<boolean> = computed(() => store.getters['account/isStaff'])
    const customerId: Ref<string | null> = ref(null)

    const updateServerOptions = (newOptions: any) => (serverOptions.value = newOptions)
    const search = (value: string) => (query.value = value)

    const headers = [
      { text: 'ID', value: 'id' },
      { text: 'Created', value: 'createdAt', sortable: true },
      { text: 'Last edit', value: 'updatedAt' },
      { text: 'Assgn. #', value: 'reference' },
      { text: 'Owner', value: 'company.name' },
      { text: 'Short name', value: 'company.shortName' },
      { text: 'Addr.', value: 'recipient' },
      { text: 'Carrier', value: 'carriers' },
      { text: 'Distribution type', value: 'distributionChannel' },
    ]

    const loadFromServer = async () => {
      loading.value = true

      const params = {
        page: serverOptions.value.page,
        limit: serverOptions.value.rowsPerPage,
        packingSlipAssignments: true,
        statusNot: 'cancelled',
        query: query.value,
        assignmentType: 'single',
        companyId: !isStaff.value ? store.getters['account/companyId'] : customerId.value,
        dateFrom: date.value?.length ? moment(date.value[0]).format('YYYY-MM-DD') : null,
        dateTo: date.value?.length ? moment(date.value[1] || date.value[0]).format('YYYY-MM-DD') : null,
        ascending: serverOptions.value.sortType === 'asc' ? 1 : 0,
        orderBy: serverOptions.value.sortBy || 'createdAt',
      }

      new AssignmentProxy(params)
        .all()
        .then((response) => {
          items.value = response.data || []
          serverItemsLength.value = response.count || 0
          loading.value = false
        })
        .catch(() => {
          serverItemsLength.value = 0
          loading.value = false
          items.value = []
        })
    }

    const updateSelected = () => {
      totalPackages.value = checkedAssignments.value.length
      totalWeight.value = checkedAssignments.value
        .map((assignment: Assignment) => assignment.item?.weight ?? 0)
        .reduce((acc, weight) => acc + weight, 0)
      store.commit(
        `packingSlip/${UPDATE_CHECKED_ASSIGNMENTS}`,
        checkedAssignments.value.map((assignment: any) => assignment.id),
      )
    }

    const setClient = (customer: string) => {
      customerId.value = customer
      store.commit(`packingSlip/${SET_COMPANY_ID}`, customer)
    }

    watch(date, loadFromServer)
    watch(query, loadFromServer)
    watch(customerId, loadFromServer)
    watch(serverOptions, loadFromServer)
    watch(checkedAssignments, updateSelected, { deep: true })

    onMounted(() => {
      openModal(modalName)
      loadFromServer()
    })

    return {
      date,
      query,
      isStaff,
      modalName,
      tableName,
      totalWeight,
      totalPackages,
      customerId,
      search,
      setClient,
      formattedDate,
      toTimeFromNow,

      // items table
      dataTable,
      items,
      headers,
      loading,
      serverOptions,
      serverItemsLength,
      checkedAssignments,
      updateServerOptions,
    }
  },
})
</script>

<style lang="scss" scoped>
.package-info {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #757575;

  span {
    color: #151515;
  }
}
.packing-slip-items-modal {
  background: #f5f5f3 !important;
}
input {
  padding: 5px;
  height: 40px;
}
.input-group-text {
  border: 1px solid #c8c8c8;
  background: #ffffff;
}
</style>
