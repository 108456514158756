//Packing Slip Store Module
import type { PackingSlipStoreState, RootStoreState } from '@/types/Store'
import type { PackingSlipState } from '@/types/Store'
import type { ActionContext } from 'vuex'
import { SINGLE } from '@/const/AssignmentTypes'
import { CREATED } from '@/const/PackingSlipEventTypes'
import { STAFF } from '@/const/AccountTypes'
import { none } from '@/services/Helpers'
import AccountProxy from '@/proxies/AccountProxy'
import PackingSlipProxy from '@/proxies/PackingSlipProxy'
import UserTransformer from '@/transformers/UserTransformer'

export const SET_PACKING_SLIPS = 'SET_PACKING_SLIPS'
export const SET_PACKING_SLIP = 'SET_PACKING_SLIP'
export const PACKING_SLIPS_UPDATE_CHECKED_ITEMS = 'PACKING_SLIPS_UPDATE_CHECKED_ITEMS'
export const PACKING_SLIPS_CLEAR_CHECKED_ITEMS = 'PACKING_SLIPS_CLEAR_CHECKED_ITEMS'
export const PACKING_SLIPS_SET_QUERY = 'PACKING_SLIPS_SET_QUERY'
export const SET_PACKING_SLIPS_ASSIGNMENT_TYPE = 'SET_PACKING_SLIPS_ASSIGNMENT_TYPE'
export const SET_COMPANY_ID = 'SET_COMPANY_ID'
export const UPDATE_CHECKED_ASSIGNMENTS = 'UPDATE_CHECKED_ASSIGNMENTS'
export const CLEAR_CHECKED_ASSIGNMENTS = 'CLEAR_CHECKED_ASSIGNMENTS'
export const FETCH_STAFFS = 'FETCH_STAFFS'

const state: PackingSlipStoreState = {
  assignmentType: 'single',
  packingSlip: {
    downloadUrl: null,
    economyPreview: null,
    id: null,
    priorityPreview: null,
    state: null,
  },
  checkedItems: [],
  checkedAssignments: [],
  packingSlips: [],
  total: 0,
  totalWeight: 0,
  query: null,
  companyId: null,
  staffs: [],
}

type Context = ActionContext<PackingSlipStoreState, RootStoreState>

const getters = {
  getPackingSlip(state: PackingSlipStoreState) {
    return state.packingSlip
  },
  getPackingSlips(state: PackingSlipStoreState) {
    return state.packingSlips
  },
  getTotalPackages(state: PackingSlipStoreState) {
    return state.total
  },
  getTotalWeights(state: PackingSlipStoreState) {
    return state.totalWeight
  },
  getCheckedItems(state: PackingSlipStoreState) {
    return state.checkedItems
  },
  getCheckedAssignments(state: PackingSlipStoreState) {
    return state.checkedAssignments
  },
  getCompanyId(state: PackingSlipStoreState) {
    return state.companyId
  },
  getAllStaffs(state: PackingSlipStoreState) {
    return state.staffs
  },
}

const mutations = {
  [SET_PACKING_SLIP](state: PackingSlipStoreState, packingSlip: PackingSlipState) {
    state.packingSlip = packingSlip
  },
  [PACKING_SLIPS_SET_QUERY](state: PackingSlipStoreState, query: string) {
    state.query = query
  },
  [SET_PACKING_SLIPS](state: PackingSlipStoreState, packingSlips: any) {
    state.packingSlips = packingSlips.data
    state.total = packingSlips.total
    state.totalWeight = packingSlips.totalWeight
  },
  [PACKING_SLIPS_UPDATE_CHECKED_ITEMS](state: PackingSlipStoreState, checkedItems: string[]) {
    state.checkedItems = checkedItems
  },
  [PACKING_SLIPS_CLEAR_CHECKED_ITEMS](state: PackingSlipStoreState) {
    state.checkedItems = []
  },
  [UPDATE_CHECKED_ASSIGNMENTS](state: PackingSlipStoreState, checkedAssignments: string[]) {
    state.checkedAssignments = checkedAssignments
  },
  [CLEAR_CHECKED_ASSIGNMENTS](state: PackingSlipStoreState) {
    state.checkedAssignments = []
  },
  [SET_PACKING_SLIPS_ASSIGNMENT_TYPE](state: PackingSlipStoreState, assignmentType: string) {
    state.assignmentType = assignmentType
  },
  [SET_COMPANY_ID](state: PackingSlipStoreState, companyId: string) {
    state.companyId = companyId
  },
  [FETCH_STAFFS](state: PackingSlipStoreState, staffs: string[]) {
    state.staffs = staffs
  },
}

const actions = {
  create: ({ commit }: Context, data: any) => {
    commit(SET_PACKING_SLIP, {
      id: null,
      downloadUrl: null,
      economyPreview: null,
      priorityPreview: null,
      state: CREATED,
    })

    return new Promise((resolve) => {
      new PackingSlipProxy()
        .create({ ...data, assignmentType: SINGLE })
        .catch(none)
        .then((response) => {
          commit(SET_PACKING_SLIP, {
            downloadUrl: response.data.downloadUrl,
            economyPreview: response.data.economyPreview,
            id: response.data.id,
            priorityPreview: response.data.priorityPreview,
            state: CREATED,
          })
          resolve(response)
        })
    })
  },
  getPackingSlips: ({ commit }: Context, data = {}) =>
    new PackingSlipProxy({ ...data, assignmentType: SINGLE })
      .all()
      .catch(none)
      .then((response) => {
        if (response && response.data) {
          commit(SET_PACKING_SLIPS, response)
        }
      }),
  deletePackingSlip: ({ commit }: Context, id: string) =>
    new PackingSlipProxy().destroy(id).then(() => {
      commit(SET_PACKING_SLIP, {
        downloadUrl: null,
        economyPreview: null,
        id: null,
        priorityPreview: null,
        state: null,
      })
    }),
  getPackingSlip: ({ commit }: Context, id: string) =>
    new PackingSlipProxy()
      .find(id)
      .catch(none)
      .then((response) => {
        if (response && response.data) {
          commit(SET_PACKING_SLIP, response.data)
        }
      }),
  removeItems: ({ commit }: Context, { id, items }: { id: string; items: string[] }) =>
    new PackingSlipProxy()
      .removeItems(id, items)
      .catch(none)
      .then(() => {
        commit(PACKING_SLIPS_UPDATE_CHECKED_ITEMS, [])
      }),
  fetchStaffs: ({ commit }: Context) =>
    new AccountProxy({ type: STAFF })
      .all()
      .catch(none)
      .then((response) => {
        if (response && response.data) {
          commit(FETCH_STAFFS, UserTransformer.fetchCollection(response.data))
        }
      }),
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}
