<template>
  <div class="Loader">
    <div v-if="isCreated" class="d-flex justify-content-center p-4">
      Loading...
      <b-spinner class="mt-1 ml-2" variant="success" small />
    </div>
    <div v-if="isGenerating" class="d-flex justify-content-center p-4">
      Generating file...
      <b-spinner class="mt-1 ml-2" variant="success" small />
    </div>
    <div v-if="isFailed" class="d-flex justify-content-center p-4">Failed to generate file, please try again.</div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, type PropType } from 'vue'
import { CREATED, FAILED, GENERATING } from '@/const/PackingSlipEventTypes'
import type { PackingSlip } from '@/types/Models/PackingSlip'

export default defineComponent({
  name: 'PackingSlipLoader',
  props: {
    packingSlip: {
      type: Object as PropType<PackingSlip | null>,
      default: null,
      required: false,
    },
  },
  setup(props) {
    const isCreated = computed(() => props.packingSlip && props.packingSlip.state === CREATED)
    const isGenerating = computed(() => props.packingSlip && props.packingSlip.state === GENERATING)
    const isFailed = computed(() => props.packingSlip && props.packingSlip.state === FAILED)

    return {
      isCreated,
      isGenerating,
      isFailed,
    }
  },
})
</script>
