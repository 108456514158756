<template>
  <div class="assignment-action d-flex mr-4 user-info">
    <div class="d-flex flex-column mr-3">
      <div v-if="company.shortName" class="color-grey">Short name</div>
      <div class="color-black">
        {{ company.shortName }}
      </div>
    </div>
    <div v-if="company.clientNumber" class="d-flex flex-column mr-3">
      <div class="color-grey">Client №</div>
      <div class="color-black">
        {{ senderAddress?.clientNumber || company.clientNumber }}
      </div>
    </div>
    <div v-if="company.name" :class="{ 'd-flex': true, 'flex-column': true, 'mr-3': !!createdDate }">
      <div class="color-grey">Company</div>
      <div v-b-tooltip="{ title: senderAddress?.email || company.email }" class="color-black">
        {{ senderAddress?.company || company.name }}
      </div>
    </div>
    <div v-if="createdDate" class="d-flex flex-column">
      <div class="color-grey">Created date</div>
      <div class="color-black">
        {{ createdDate }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { Assignment } from '@/types/Models/Assignment'
import type { Address } from '@/types/Models/Customer'
import type { Company } from '@/types/Models/Company'
import { useStore } from 'vuex'
import { readableDateTime } from '@/services/Helpers'
import { defineComponent, computed, type ComputedRef } from 'vue'

export default defineComponent({
  name: 'CompanyInfo',
  setup() {
    const store = useStore()

    const assignment: ComputedRef<Assignment> = computed(() => store.getters['assignment/assignmentSummary/get'])
    const company: ComputedRef<Company> = computed(() => assignment.value.company)
    const senderAddress: ComputedRef<Address | null | undefined> = computed(() => assignment.value.senderAddress)
    const createdDate: ComputedRef<string | null> = computed(() => readableDateTime(assignment.value.createdAt))

    return {
      company,
      createdDate,
      senderAddress,
    }
  },
})
</script>
