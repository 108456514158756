<template>
  <div class="create-customer-form">
    <VeeForm v-slot="{ setFieldValue, getValues }" @submit="handleSubmit">
      <b-form-group label="General Info">
        <Field v-slot="{ field, errors, meta }" name="email" :rules="rules.email">
          <div class="form-group">
            <div class="input-group">
              <input
                v-bind="field"
                v-model="customer.email"
                :class="['form-control', getValidationState(meta)]"
                type="text"
                placeholder="Email address *"
              />
              <div v-if="errors.length" class="invalid-feedback email">
                {{ errors[0] }}
              </div>
            </div>
          </div>
        </Field>

        <Field v-slot="{ field, errors, meta }" name="clientNumber" :rules="rules.clientNumber">
          <div class="form-group">
            <div class="input-group">
              <input
                v-bind="field"
                v-model="customer.clientNumber"
                :class="['form-control', getValidationState(meta)]"
                type="text"
                placeholder="Client number *"
              />
              <div v-if="errors.length" class="invalid-feedback clientNumber">
                {{ errors[0] }}
              </div>
            </div>
          </div>
        </Field>

        <Field v-slot="{ field, errors, meta }" name="firstName" :rules="rules.firstName">
          <div class="form-group">
            <div class="input-group">
              <input
                v-bind="field"
                v-model="customer.firstName"
                :class="['form-control', getValidationState(meta)]"
                type="text"
                placeholder="First name *"
              />
              <div v-if="errors.length" class="invalid-feedback firstName">
                {{ errors[0] }}
              </div>
            </div>
          </div>
        </Field>

        <Field v-slot="{ field, errors, meta }" name="lastName" :rules="rules.lastName">
          <div class="form-group">
            <div class="input-group">
              <input
                v-bind="field"
                v-model="customer.lastName"
                :class="['form-control', getValidationState(meta)]"
                type="text"
                placeholder="Last name *"
              />
              <div v-if="errors.length" class="invalid-feedback lastName">
                {{ errors[0] }}
              </div>
            </div>
          </div>
        </Field>

        <Field v-slot="{ field, errors, meta }" name="company" :rules="rules.company">
          <div class="form-group">
            <div class="input-group">
              <input
                v-bind="field"
                v-model="customer.company"
                :class="['form-control', getValidationState(meta)]"
                type="text"
                placeholder="Company *"
              />
              <div v-if="errors.length" class="invalid-feedback company">
                {{ errors[0] }}
              </div>
            </div>
          </div>
        </Field>
      </b-form-group>

      <b-form-group label="Invoice Address" class="form-group">
        <Field v-slot="{ field, errors, meta }" name="clientAddress.countryCode" :rules="rules.country">
          <div class="p-bot-15">
            <CountryPicker
              v-bind="field"
              v-model="customer.clientAddress.countryCode"
              :class="['form-control', getValidationState(meta)]"
              placeholder="Choose country *"
              @select="getValidationState"
              @change="invoiceCountry"
            />
            <div v-if="errors.length" class="invalid-feedback countryCode">
              {{ errors[0] }}
            </div>
          </div>
        </Field>

        <div class="d-flex flex-row">
          <Field v-slot="{ field, errors, meta }" name="clientAddress.city" :rules="rules.city">
            <div class="form-group client-city">
              <div class="input-group">
                <input
                  v-bind="field"
                  v-model="customer.clientAddress.city"
                  :class="['form-control', getValidationState(meta)]"
                  type="text"
                  placeholder="Client City *"
                />
                <div v-if="errors.length" class="invalid-feedback city">
                  {{ errors[0] }}
                </div>
              </div>
            </div>
          </Field>

          <Field
            v-slot="{ field, errors, meta }"
            name="clientAddress.postalCode"
            :rules="{ zip: customer.clientAddress.countryCode }"
          >
            <div class="form-group client-zip">
              <div class="input-group">
                <input
                  v-bind="field"
                  v-model="customer.clientAddress.postalCode"
                  :class="['form-control', getValidationState(meta)]"
                  type="text"
                  placeholder="ZIP *"
                />
                <div v-if="errors.length" class="invalid-feedback postalCode">
                  {{ errors[0] }}
                </div>
              </div>
            </div>
          </Field>
        </div>

        <Field v-slot="{ field, errors, meta }" name="clientAddress.addressLine" :rules="rules.addressLine">
          <div class="form-group">
            <div class="input-group">
              <input
                v-bind="field"
                v-model="customer.clientAddress.addressLine"
                :class="['form-control', getValidationState(meta)]"
                type="text"
                placeholder="Client Address 1 *"
              />
              <div v-if="errors.length" class="invalid-feedback addressLine">
                {{ errors[0] }}
              </div>
            </div>
          </div>
        </Field>

        <Field v-slot="{ field, errors }" name="clientAddress.addressLine2">
          <div class="form-group">
            <div class="input-group">
              <input
                v-bind="field"
                v-model="customer.clientAddress.addressLine2"
                class="form-control"
                type="text"
                placeholder="Client Address 2"
              />
              <div v-if="errors.length" class="invalid-feedback addressLine2">
                {{ errors[0] }}
              </div>
            </div>
          </div>
        </Field>
      </b-form-group>

      <fieldset key="sender_address" class="form-group">
        <legend class="d-flex align-items-end justify-content-between bv-no-focus-ring col-form-label pt-0">
          <span>Sender Address</span>
          <Tooltip text="Copy Invoice Address details to the Sender Address fields">
            <span class="text-muted text-sm pointer" @click="copyToInvoiceAddress(setFieldValue, getValues)">
              <mdicon name="arrow-down" size="16" />
              Same as an invoice address
            </span>
          </Tooltip>
        </legend>

        <Field v-slot="{ field, errors, meta }" name="senderAddress.countryCode" :rules="rules.country">
          <div class="p-bot-15">
            <CountryPicker
              v-bind="field"
              v-model="customer.senderAddress.countryCode"
              :class="['form-control', getValidationState(meta)]"
              placeholder="Choose country *"
              @change="senderCountry"
            />
            <div v-if="errors.length" class="invalid-feedback countryCode">
              {{ errors[0] }}
            </div>
          </div>
        </Field>

        <div class="d-flex flex-row">
          <Field v-slot="{ field, errors, meta }" name="senderAddress.city" :rules="rules.city">
            <div class="form-group client-city">
              <div class="input-group">
                <input
                  v-bind="field"
                  v-model="customer.senderAddress.city"
                  :class="['form-control', getValidationState(meta)]"
                  type="text"
                  placeholder="Sender City *"
                />
                <div v-if="errors.length" class="invalid-feedback city">
                  {{ errors[0] }}
                </div>
              </div>
            </div>
          </Field>

          <Field
            v-slot="{ field, errors, meta }"
            name="senderAddress.postalCode"
            :rules="{ zip: customer.senderAddress.countryCode }"
          >
            <div class="form-group client-zip">
              <div class="input-group">
                <input
                  v-bind="field"
                  v-model="customer.senderAddress.postalCode"
                  :class="['form-control', getValidationState(meta)]"
                  type="text"
                  placeholder="ZIP *"
                />
                <div v-if="errors.length" class="invalid-feedback postalCode">
                  {{ errors[0] }}
                </div>
              </div>
            </div>
          </Field>
        </div>

        <Field v-slot="{ field, errors, meta }" name="senderAddress.addressLine" :rules="rules.addressLine">
          <div class="form-group">
            <div class="input-group">
              <input
                v-bind="field"
                v-model="customer.senderAddress.addressLine"
                :class="['form-control', getValidationState(meta)]"
                type="text"
                placeholder="Sender Address 1 *"
              />
              <div v-if="errors.length" class="invalid-feedback addressLine">
                {{ errors[0] }}
              </div>
            </div>
          </div>
        </Field>

        <Field v-slot="{ field, errors, meta }" name="senderAddress.addressLine2">
          <div class="form-group">
            <div class="input-group">
              <input
                v-bind="field"
                v-model="customer.senderAddress.addressLine2"
                :class="['form-control', getValidationState(meta)]"
                type="text"
                placeholder="Sender Address 2"
              />
              <div v-if="errors.length" class="invalid-feedback addressLine2">
                {{ errors[0] }}
              </div>
            </div>
          </div>
        </Field>
      </fieldset>

      <b-form-group label="Other Info">
        <Field v-slot="{ field, errors, meta }" name="phoneNumber" :rules="rules.phoneNumber">
          <div class="form-group">
            <div class="input-group">
              <input
                v-bind="field"
                v-model="customer.phoneNumber"
                :class="['form-control', getValidationState(meta)]"
                type="text"
                placeholder="Phone number *"
              />
              <div v-if="errors.length" class="invalid-feedback phoneNumber">
                {{ errors[0] }}
              </div>
            </div>
          </div>
        </Field>

        <Field v-slot="{ field, errors, meta }" name="shortName" :rules="rules.shortName">
          <div class="form-group">
            <div class="input-group">
              <input
                v-bind="field"
                v-model="customer.shortName"
                :class="['form-control', getValidationState(meta)]"
                type="text"
                placeholder="Short name"
              />
              <div v-if="errors.length" class="invalid-feedback shortName">
                {{ errors[0] }}
              </div>
            </div>
          </div>
        </Field>

        <Field v-slot="{ value }" :model-value="customer.contractParty" name="contractParty">
          <div class="form-group">
            <div class="input-group">
              <select :value="value" class="form-control">
                <option v-for="(contract, key) in contracts" :key="key" :value="contract.value">
                  {{ contract.text }}
                </option>
              </select>
            </div>
          </div>
        </Field>

        <Field v-slot="{ field, value }" :model-value="customer.salesmanId" name="salesmanId">
          <div class="form-group">
            <select v-bind="field" class="form-control">
              <option
                v-for="(staff, key) in staffsOptions"
                :key="key"
                :value="staff.value"
                :selected="value === staff.value"
              >
                {{ staff.text }}
              </option>
            </select>
          </div>
        </Field>

        <b-form-group label="Send notifications" class="mb-2 mt-30 form-group">
          <Field v-slot="{ field }" name="shipmentNotifications" type="checkbox" :value="true" :unchecked-value="false">
            <div class="form-check">
              <input
                v-bind="field"
                id="shipmentNotifications"
                :value="true"
                type="checkbox"
                class="form-check-input"
                name="shipmentNotifications"
              />
              <label class="form-check-label" for="shipmentNotifications">
                Keep this checked for all normal customers. If Unchecked, the user will not receive welcome email nor
                any future notifications.
              </label>
            </div>
          </Field>
        </b-form-group>

        <b-form-group label="Custom Options" class="mt-4 form-group">
          <Field v-slot="{ field }" name="hidePrice" type="checkbox" :value="true" :unchecked-value="false">
            <div class="form-check">
              <input
                v-bind="field"
                id="hidePrice"
                :value="true"
                type="checkbox"
                class="form-check-input"
                name="hidePrice"
              />
              <label class="form-check-label" for="hidePrice">
                Uncheck this if you don't want to show optimized prices for the customer
              </label>
            </div>
          </Field>

          <Field v-slot="{ field }" name="canHasCustomerNumbers" type="checkbox" :value="true" :unchecked-value="false">
            <div class="form-check mt-3">
              <input
                v-bind="field"
                id="canHasCustomerNumbers"
                :value="true"
                type="checkbox"
                class="form-check-input"
                name="canHasCustomerNumbers"
              />
              <label class="form-check-label" for="canHasCustomerNumbers">
                The customer can use the carrier's own customer number.
              </label>
            </div>
          </Field>
        </b-form-group>
      </b-form-group>

      <b-alert :model-value="serverErrors" variant="danger">
        Customer not created, please check fields errors or contact administrator
      </b-alert>

      <b-button type="submit" class="w-100"> Register customer </b-button>
    </VeeForm>
  </div>
</template>

<script lang="ts">
import type { GetValuesType, SetFieldValueType } from '@/types/Helpers/VeeValidate'
import type { CreateCustomer } from '@/types/Models/Customer'
import type { SelectOptions } from '@/types/Components/SelectOptions'
import type { User } from '@/types/Models/User'
import { ref, defineComponent, type Ref, onMounted } from 'vue'
import { notify } from '@kyvg/vue3-notification'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { Form as VeeForm, Field } from 'vee-validate'
import { removeEmpty } from '@/services/Helpers'
import { findCountryCode } from '@/services/Country'
import { getValidationState } from '@/services/Validation'
import CountryPicker from '@/views/Components/Elements/Form/CountryPicker.vue'
import Tooltip from '@/views/Components/Elements/Tooltip.vue'

export default defineComponent({
  name: 'CreateCustomerForm',
  components: {
    Tooltip,
    Field,
    VeeForm,
    CountryPicker,
  },
  props: {
    routeAfterSave: {
      type: Object,
      required: false,
      default: null,
    },
    created: {
      type: Function,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const customer: Ref<CreateCustomer> = ref({
      email: '',
      clientNumber: null,
      firstName: '',
      lastName: '',
      company: '',
      clientAddress: {
        countryCode: null,
        city: null,
        postalCode: null,
        addressLine: null,
        addressLine2: null,
      },
      senderAddress: {
        countryCode: null,
        city: null,
        postalCode: null,
        addressLine: null,
        addressLine2: null,
      },
      phoneNumber: '',
      shortName: '',
      contractParty: null,
      salesmanId: store.getters['account/userId'],
      shipmentNotifications: true,
      hidePrice: true,
      canHasCustomerNumbers: true,
    })
    const serverErrors: Ref<boolean> = ref(false)
    const staffsOptions: Ref<any> = ref([])

    const rules = {
      email: 'required|email',
      clientNumber: 'required|integer',
      firstName: 'required|alpha_spaces|min:3',
      lastName: 'required|alpha_spaces|min:3',
      company: 'required',
      country: 'required',
      city: 'required',
      addressLine: 'required',
      phoneNumber: 'required',
      shortName: 'alpha|min:3|max:4',
    }

    const contracts: SelectOptions[] = [
      { value: '', text: 'Contract party' },
      { value: 'Mailworld', text: 'Mailworld' },
      { value: '21 grams', text: '21 grams' },
      { value: 'Europe Post', text: 'Europe Post' },
    ]

    const createCustomer = async (values: CreateCustomer) => {
      const data: any = removeEmpty(values)
      data.hidePrice = !data.hidePrice
      data.canHasCustomerNumbers = !data.canHasCustomerNumbers
      data.shipmentNotifications = !data.shipmentNotifications
      data.senderAddress.countryCode = findCountryCode(data.senderAddress.countryCode)
      data.clientAddress.countryCode = findCountryCode(data.clientAddress.countryCode)
      try {
        store
          .dispatch('customers/create', data)
          .then(() => {
            if (props.created) {
              props.created()
            }
            if (props.routeAfterSave) {
              router.push(props.routeAfterSave)
            }
          })
          .catch((e: any) => {
            const errors = Object.keys(e.errors)
            errors.forEach((field: string) => {
              e.errors[field].forEach((text: string) => {
                notify({ group: 'error', text })
              })
            })
          })
      } catch (response) {
        serverErrors.value = true
      }
    }

    const handleSubmit = (data: any) => {
      createCustomer(data)
    }

    const copyToInvoiceAddress = (setFieldValue: SetFieldValueType, getValues: GetValuesType) => {
      const values = getValues()
      setFieldValue('senderAddress.countryCode', values.clientAddress.countryCode)
      setFieldValue('senderAddress.city', values.clientAddress.city)
      setFieldValue('senderAddress.postalCode', values.clientAddress.postalCode)
      setFieldValue('senderAddress.addressLine', values.clientAddress.addressLine)
      setFieldValue('senderAddress.addressLine', values.clientAddress.addressLine2)
    }
    const invoiceCountry = (e: string) => {
      customer.value.clientAddress.countryCode = e
    }
    const senderCountry = (e: string) => {
      customer.value.senderAddress.countryCode = e
    }

    onMounted(async () => {
      const result = [{ value: null, text: 'Choose Assigned Salesman' }]
      const staffs = await store.getters['packingSlip/getAllStaffs']

      if (staffs?.length) {
        result.push(
          ...staffs.map((staff: User) => ({
            value: staff.id,
            text: staff.firstName ? `${staff.firstName} ${staff.lastName}` : staff.email,
          })),
        )
      }
      staffsOptions.value = result
    })

    return {
      customer,
      rules,
      contracts,
      staffsOptions,
      serverErrors,
      handleSubmit,
      senderCountry,
      invoiceCountry,
      createCustomer,
      getValidationState,
      copyToInvoiceAddress,
    }
  },
})
</script>

<style scoped>
.client-city {
  margin-right: 16px;
  width: 66%;
}
.client-zip {
  width: 34%;
}
.p-bot-15 {
  margin-bottom: 1rem;
}

:deep(legend) {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
.text-muted {
  color: #a8afbb !important;
  margin-left: auto;
}
input.form-control {
  padding: 13px;
}
.mt-30 {
  margin-top: 30px;
}
</style>
