import type { Assignment } from '@/types/Models/Assignment'
import { computed } from 'vue'
import { useStore } from 'vuex'
import { CUSTOMER_ASSIGNMENTS_DETAILS, STAFF_ASSIGNMENTS_DETAILS } from '@/types/router'

export const useDetailsRoute = () => {
  const store = useStore()

  const isStaff = computed(() => store.getters['account/isStaff'])

  const getDetailsRoute = (assignment: Assignment) => {
    return {
      name: isStaff.value ? STAFF_ASSIGNMENTS_DETAILS : CUSTOMER_ASSIGNMENTS_DETAILS,
      params: {
        id: assignment.reference,
      },
    }
  }

  return {
    getDetailsRoute,
  }
}
