// Helpers
import type { App } from 'vue'
import type { AxiosErrors } from '@/types/Components/Response'
import { CARRIERS_NAMES } from '@/const/Carriers'
import { TRACKING_STATUS_NAMES } from '@/const/TrackingEventStatuses'
import { notify } from '@kyvg/vue3-notification'
import moment from 'moment/moment'
import store from '@/store'

export const ucFirst = (str: string) => (str ? str[0].toUpperCase() + str.slice(1) : '')
export const formattedDate = (date: Date | string, format: string) => (date ? moment(date).format(format) : '')
export const readableTime = (date: Date | string | null) => (date ? moment(date).format('HH:mm') : '')
export const toTimeFromNow = (date: Date | string | null) => (date ? moment(date).fromNow() : '')
export const capitalize = (value: string) => (value ? value.toUpperCase() : value)
export const carrierHandler2Name = (carrierHandler: string) => CARRIERS_NAMES[carrierHandler] || carrierHandler
export const trackingStatus2Name = (status: string, fallback: string) => TRACKING_STATUS_NAMES[status] || fallback
export const pluralize = (singular: string, amount: number, plural?: string) =>
  amount > 1 || amount === 0 ? plural || `${singular}s` : singular

export const removeEmpty = (obj: any): object => {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([, v]) => v)
      .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v]),
  )
}

export const readableDate = (date: Date | string | null) => {
  if (!date) return date

  const parsed = moment(date)
  const format = parsed.year() !== moment().year() ? 'DD MMM YY' : 'DD MMM'

  return parsed.format(format)
}

export const readableDateTime = (date: string | null) => {
  if (!date) return date

  const parsed = moment(date)
  const format = parsed.year() !== moment().year() ? 'DD MMM YY h:mm' : 'DD MMM h:mm'

  return parsed.format(format)
}

export const fileNameTrim = (input: string) => {
  if (!input) {
    return ''
  }

  const parts = input.split('/')

  const last = parts[parts.length - 1]

  if (last.length <= 25) {
    return last
  }

  return `${last.substr(0, 10)}...${last.substr(last.length - 15)}`
}

export const none = () => {}

export const catchErrors = (e: AxiosErrors) => {
  let message = 'An error occurred'
  if (e.message) {
    message = e.message
  } else if (e.errors && Object.keys(e.errors).length) {
    const errors = Object.keys(e.errors)
    errors.forEach((field: string) => {
      e.errors![field].forEach((text: string) => {
        message += `\n${field}: ${text}`
      })
    })
  }

  notify({ group: 'error', text: message })
}

export const numbersOnly = (value: string) => value.replace(/[^0-9_@./)(#&+-]+/gm, '')

export const formattedNumber = (value: number | null) => {
  if (value) {
    return parseFloat(String(value)).toLocaleString('sv-SE', {
      maximumFractionDigits: 2,
    })
  }
  return 0
}

export const setupApp = async (app?: App) => {
  try {
    const baseUrl = import.meta.env.VITE_CDN_URL ? import.meta.env.VITE_CDN_URL : ''

    const response = await fetch(baseUrl + '/version.txt', { cache: 'no-store' })
    const latestVersion = await response.text()
    const currentVersion = localStorage.getItem('app-version')

    if (latestVersion && currentVersion !== latestVersion) {
      localStorage.setItem('app-version', latestVersion)
      window.location.reload() // force reset cache
    } else {
      await store.dispatch('auth/check')
      app?.mount('#app')
    }
  } catch {
    await store.dispatch('auth/check')
    app?.mount('#app')
  }
}

export const getSystemDisplayName = (system: string) => {
  switch (system) {
    case 'shopify':
      return 'Shopify'
    case 'woocommerce':
      return 'WooCommerce'
    case 'ongoing':
      return 'OnGoing'
    default:
      return system
  }
}
