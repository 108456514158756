<template>
  <modal-wrap
    :name="modalName"
    :header-class="'sender-address-header'"
    body-class="sender-address-modal"
    :hide-footer="true"
    size="xl"
  >
    <template #modal-title>
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="mb-0">{{ modalTitle }}</h4>
        <b-badge v-if="isDefaultSenderAddress" class="default-label ml-2" variant="success"> Default </b-badge>
        <b-badge v-if="selectedSender.hasCustomPrices" variant="success" class="ml-1"> Sender prices </b-badge>
      </div>
    </template>
    <template #default>
      <div v-if="addressId !== ''" class="back-btn" @click="returnToAddressesListModal">
        <mdicon name="ArrowLeft" class="back-btn-icon" />
        <div class="back-btn-text">Back</div>
      </div>
      <VeeForm @submit="handleSubmit">
        <div class="mt-2">
          <div class="d-flex mr-4">
            <div class="form-group col-xl-6 mr-4">
              <Field
                v-slot="{ field, errors, meta }"
                :model-value="selectedSender.company"
                name="company"
                :rules="{ required: true, min: 3 }"
              >
                <label for="company">Company *</label>
                <input
                  v-bind="field"
                  v-model="selectedSender.company"
                  type="text"
                  :class="['form-control', getValidationState(meta)]"
                />
                <span class="text-danger">{{ errors.join() }}</span>
              </Field>
            </div>
            <div class="form-group col-xl-6">
              <Field v-slot="{ field, errors, meta }" :model-value="selectedSender.coAddress" name="coAddress">
                <label for="coAddress">C/O company</label>
                <input
                  v-bind="field"
                  v-model="selectedSender.coAddress"
                  type="text"
                  :class="['form-control', getValidationState(meta)]"
                />
                <span class="text-danger">{{ errors.join() }}</span>
              </Field>
            </div>
          </div>

          <div class="d-flex mr-4">
            <div class="form-group col-xl-6 mr-4">
              <Field
                v-slot="{ field, errors, meta }"
                :model-value="selectedSender.phoneNumber"
                :rules="`required|phone:${selectedSender.countryCode}`"
                name="phoneNumber"
              >
                <label for="phoneNumber">Phone number*</label>
                <input
                  v-bind="field"
                  v-model="selectedSender.phoneNumber"
                  type="text"
                  :class="['form-control', getValidationState(meta)]"
                />
                <span class="text-danger">{{ errors.join() }}</span>
              </Field>
            </div>
            <div class="form-group col-xl-6">
              <Field
                v-slot="{ field, errors, meta }"
                :model-value="selectedSender.email"
                name="email"
                :rules="{ required: true, email: true }"
              >
                <label for="email">Email *</label>
                <input
                  v-bind="field"
                  v-model="selectedSender.email"
                  type="email"
                  :class="['form-control', getValidationState(meta)]"
                />
                <span class="text-danger">{{ errors.join() }}</span>
              </Field>
            </div>
          </div>

          <div class="d-flex mr-4">
            <div class="form-group col-xl-6 mr-4">
              <Field
                v-slot="{ field, errors, meta }"
                :model-value="selectedSender.countryCode"
                name="countryCode"
                :rules="{ required: true }"
              >
                <label for="country">Country *</label>
                <CountryPicker
                  v-bind="field"
                  :class="['form-control', getValidationState(meta)]"
                  @change="selectCountry"
                />
                <span class="text-danger">{{ errors.join() }}</span>
              </Field>
            </div>
            <div class="col-xl-6 d-flex flex-row">
              <div class="form-group client-city">
                <Field
                  v-slot="{ field, errors, meta }"
                  :model-value="selectedSender.city"
                  name="city"
                  :rules="{ required: true }"
                >
                  <label for="city">City*</label>
                  <input
                    v-bind="field"
                    v-model="selectedSender.city"
                    type="text"
                    :class="['form-control', getValidationState(meta)]"
                  />
                  <span class="text-danger">{{ errors.join() }}</span>
                </Field>
              </div>
              <div class="form-group col-xl-6">
                <Field
                  v-slot="{ field, errors, meta }"
                  :model-value="selectedSender.postalCode"
                  name="postalCode"
                  :rules="{ required: true, zip: true }"
                >
                  <label for="zip">ZIP *</label>
                  <input
                    v-bind="field"
                    v-model="selectedSender.postalCode"
                    type="text"
                    :class="['form-control', getValidationState(meta)]"
                  />
                  <span class="text-danger">{{ errors.join() }}</span>
                </Field>
              </div>
            </div>
          </div>

          <div class="d-flex mr-4">
            <div class="form-group col-xl-6 mr-4">
              <Field
                v-slot="{ field, errors, meta }"
                :model-value="selectedSender.addressLine"
                name="addressLine"
                :rules="{ required: true }"
              >
                <label for="addressLine">Client Address *</label>
                <input
                  v-bind="field"
                  v-model="selectedSender.addressLine"
                  type="text"
                  :class="['form-control', getValidationState(meta)]"
                />
                <span class="text-danger">{{ errors.join() }}</span>
              </Field>
            </div>
            <div class="form-group col-xl-6">
              <Field v-slot="{ field }" :model-value="selectedSender.addressLine2" name="address2">
                <label for="addressLine2">Client Address 2</label>
                <input v-bind="field" v-model="selectedSender.addressLine2" type="text" class="form-control" />
              </Field>
            </div>
          </div>

          <div class="d-flex mr-4">
            <div class="form-group col-xl-6 mr-4">
              <Field
                v-slot="{ field, errors, meta }"
                :model-value="selectedSender.clientNumber"
                name="clientNumber"
                :rules="{ max: 16 }"
              >
                <label for="addressLine">Client №</label>
                <input
                  v-bind="field"
                  v-model="selectedSender.clientNumber"
                  type="text"
                  :class="['form-control', getValidationState(meta)]"
                />
                <span class="text-danger">{{ errors.join() }}</span>
              </Field>
            </div>
            <div class="form-group col-xl-6">
              <Field v-slot="{ field, errors, meta }" :model-value="selectedSender.defaultFor" name="defaultFor">
                <label for="country">Default for</label>
                <CountryPicker
                  v-bind="field"
                  :class="['form-control', getValidationState(meta)]"
                  @change="selectDefaultFor"
                />
                <span class="text-danger">{{ errors.join() }}</span>
              </Field>
            </div>
          </div>

          <div v-if="modalData?.canHasCustomerNumbers">
            <h4>Customer Numbers</h4>
            <div class="accordion d-flex flex-gap" role="tablist">
              <b-card v-for="(service, index) in customerNumbers" :key="service.title" no-body class="mb-1 flex-pad">
                <b-card-header header-tag="header" class="p-0" role="tab">
                  <b-button v-b-toggle="`accordion-${index}`" block variant="primary" class="px-3 py-0">
                    <div class="d-flex service-name align-items-center">
                      <img :src="getServiceImage(service.logo)" alt="" />
                      <span>{{ service.title }}</span>
                    </div>
                  </b-button>
                </b-card-header>
                <b-collapse :id="'accordion-' + index" visible accordion="my-accordion" role="tabpanel">
                  <b-card-body class="p-3">
                    <div class="mail-service">
                      <div class="d-flex align-items-center service-choose">
                        <div class="d-flex align-items-center mr-3 cursor-pointer" @click="service.active = false">
                          <img src="../../../assets/img/services/21grams.svg" alt="" />
                          21grams
                        </div>
                        <b-form-checkbox v-model="service.active" name="check-button" switch />
                        <div class="d-flex align-items-center ml-2 cursor-pointer" @click="service.active = true">
                          <img src="../../../assets/img/services/customer.svg" alt="" />
                          <div>Customer</div>
                        </div>
                      </div>
                      <div class="mt-4">
                        <div class="row">
                          <div class="col-12">
                            <b-form-input
                              v-model="service.number"
                              placeholder="EKP"
                              :class="{ 'field-disabled': !service.active }"
                              :disabled="!service.active"
                              size="sm"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
          <div class="sender-footer mt-3">
            <div v-if="!address" class="w-100 m-0">
              <b-button type="submit" variant="success" class="w-100"> Create </b-button>
            </div>
            <div v-else class="row w-100 m-0">
              <div class="col-2 pr-2 pl-0">
                <b-button
                  class="w-100 d-flex align-items-center justify-content-center action-btn"
                  variant="outline-secondary"
                  @click="destroySenderAddress"
                >
                  <mdicon name="DeleteOutline" size="24" />
                </b-button>
              </div>
              <div class="col-2 pr-2 pl-0">
                <b-button
                  v-if="isStaff"
                  v-b-tooltip.hover.bottom
                  class="w-100 d-flex align-items-center justify-content-center action-btn"
                  variant="outline-secondary"
                  title="Set sender address as default"
                  @click="setSenderAddressAsDefault"
                >
                  <mdicon name="Cogs" size="24" />
                </b-button>
              </div>
              <div class="col-8 p-0">
                <b-button type="submit" variant="success" class="w-100">Save</b-button>
              </div>
            </div>
          </div>
        </div>
      </VeeForm>
    </template>
  </modal-wrap>
</template>

<script lang="ts">
import type { Address, CustomerNumber } from '@/types/Models/Customer'
import { useStore } from 'vuex'
import { none } from '@/services/Helpers'
import { defineComponent, computed, ref, onMounted, watch, toRaw, type Ref, type ComputedRef } from 'vue'
import { findCountryCode, findCountryName } from '@/services/Country'
import { getValidationState } from '@/services/Validation'
import { Form as VeeForm, Field } from 'vee-validate'
import { SENDER_ADDRESS_ACTION, SENDER_ADDRESSES } from '@/const/ModalTypes'
import ModalWrap from '@/views/Modals/ModalWrap.vue'
import CountryPicker from '@/views/Components/Elements/Form/CountryPicker.vue'

interface CustomerNumbersList {
  link: string
  title: string
  active: boolean
  logo: string
  carrierHandle: string
  number: string
}

interface ModalData {
  id: string
  customerId: string
  canHasCustomerNumbers: boolean
}

export default defineComponent({
  name: 'SenderAddressActionModal',
  components: {
    ModalWrap,
    CountryPicker,
    VeeForm,
    Field,
  },
  props: {
    addressId: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props) {
    const store = useStore()

    const modalName: string = SENDER_ADDRESS_ACTION
    const modalData: ComputedRef<ModalData> = computed(() => store.getters['vueNextModal/modalProps'](modalName))
    const countryName: Ref<string> = ref('')
    const selectedSender: Ref<Address> = ref({
      id: '',
      phoneNumber: '',
      email: '',
      countryCode: '',
      city: '',
      postalCode: '',
      addressLine: '',
      addressLine2: '',
      company: '',
      coAddress: '',
      defaultFor: null,
      hasCustomPrices: false,
      customNumbers: [],
      type: 'sender',
      firstName: '',
      lastName: '',
      fullName: '',
      isDefault: false,
      clientNumber: '',
    })
    const customerNumbers: Ref<CustomerNumbersList[]> = ref([
      {
        link: 'https://www.deutschepost.com/',
        title: 'Postnord',
        active: false,
        logo: 'postnord-logo.svg',
        carrierHandle: 'postnord',
        number: '',
      },
      {
        link: 'https://www.deutschepost.com/',
        title: 'Deutsche Post',
        active: false,
        logo: 'deutschepost.svg',
        carrierHandle: 'deutschepost',
        number: '',
      },
      {
        link: 'https://www.deutschepost.com/',
        title: 'Citymail',
        active: false,
        logo: 'citymail-logo.svg',
        carrierHandle: 'citymail',
        number: '',
      },
      {
        link: 'https://www.deutschepost.com/',
        title: 'Asendia',
        active: false,
        logo: 'asendia-logo.svg',
        carrierHandle: 'asendia',
        number: '',
      },
    ])
    const isStaff: ComputedRef<boolean> = computed(() => store.getters['account/isStaff'])
    const address: ComputedRef<string> = computed(() => modalData.value?.id || props.addressId)
    const modalTitle: ComputedRef<string> = computed(() =>
      address.value !== '' ? 'Edit Sender Address' : 'Create Sender Address',
    )
    const isDefaultSenderAddress: ComputedRef<boolean> = computed(
      () => address.value !== '' && !!selectedSender.value.isDefault,
    )
    const baseURL: string = import.meta.env.BASE_URL.endsWith('/')
      ? import.meta.env.BASE_URL.slice(0, -1)
      : import.meta.env.BASE_URL

    const getServiceImage = (serviceLogo: string) => `${baseURL}/assets/img/services/${serviceLogo}`

    const handleSubmit = () => {
      if (address.value) {
        updateSenderAddress()
      } else {
        createSenderAddress()
      }
    }

    const returnToAddressesListModal = () => {
      store.dispatch('vueNextModal/closeModal', modalName)
    }

    const prepareRequest = () => {
      const data = {
        ...toRaw(selectedSender.value),
        customerNumbers: [] as CustomerNumber[],
      }
      data.customerNumbers = data.customNumbers?.filter((el: CustomerNumber) => el.active) || []
      return data
    }

    const closeAddressModal = () => {
      if (modalData.value.customerId) {
        store.dispatch('customers/senderAddresses/fetch', modalData.value.customerId).catch(none)
      }
      store.dispatch('vueNextModal/closeModal', modalName)
    }

    const createSenderAddress = () => {
      const data = prepareRequest()
      store
        .dispatch('customers/senderAddresses/store', {
          companyId: modalData.value.customerId,
          data,
        })
        .then(closeAddressModal)
        .catch(none)
    }

    const updateSenderAddress = () => {
      const payload = {
        companyId: modalData.value.customerId,
        addressId: address.value,
        sender: prepareRequest(),
      }
      store.dispatch('customers/senderAddresses/updateSenderAddress', payload).then(closeAddressModal).catch(none)
    }

    const destroySenderAddress = () => {
      store
        .dispatch('customers/senderAddresses/destroySenderAddress', {
          companyId: modalData.value.customerId,
          addressId: address.value,
        })
        .then(closeAddressModal)
        .catch(none)
    }

    const setSenderAddressAsDefault = () => {
      store
        .dispatch('customers/senderAddresses/setAsDefaultSenderAddress', {
          companyId: modalData.value.customerId,
          addressId: address.value,
        })
        .then(loadAddresses)
        .catch(none)
    }

    const selectCountry = (country?: string | null) => {
      if (!country || country.length === 0) {
        countryName.value = ''
        selectedSender.value.countryCode = ''
        return
      }
      const cleanCode = country.split(' - ')[0]
      selectedSender.value.countryCode = findCountryCode(cleanCode) ?? ''
      countryName.value = findCountryName(cleanCode) ?? ''
    }

    const selectDefaultFor = (country?: string | null) => {
      if (!country || country.length === 0) {
        countryName.value = ''
        selectedSender.value.defaultFor = ''
        return
      }
      const cleanCode = country.split(' - ')[0]
      selectedSender.value.defaultFor = findCountryCode(cleanCode) ?? ''
      countryName.value = findCountryName(cleanCode) ?? ''
    }

    const loadAddresses = () => {
      if (address.value) {
        store
          .dispatch('customers/senderAddresses/selectSenderAddress', {
            companyId: modalData.value.customerId,
            addressId: address.value,
          })
          .then(() => {
            selectedSender.value = store.getters['customers/senderAddresses/getSelectedSenderAddress'] || {}
            selectCountry(selectedSender.value.countryCode)
            customerNumbers.value.map((el: CustomerNumber) => {
              const customNumber =
                selectedSender.value?.customNumbers?.find(
                  (num: CustomerNumber) => num.carrierHandle === el.carrierHandle,
                ) || null
              if (customNumber !== null) {
                el.active = !!customNumber.active
                el.number = customNumber.number
              } else {
                el.active = false
                el.number = ''
              }
              return el
            })
          })
          .catch(none)
      }
    }

    watch(address, loadAddresses, { deep: true })

    onMounted(loadAddresses)

    return {
      address,
      countryName,
      modalName,
      modalData,
      selectedSender,
      customerNumbers,
      isStaff,
      modalTitle,
      isDefaultSenderAddress,
      handleSubmit,
      returnToAddressesListModal,
      createSenderAddress,
      updateSenderAddress,
      destroySenderAddress,
      setSenderAddressAsDefault,
      selectCountry,
      selectDefaultFor,
      getValidationState,
      getServiceImage,
    }
  },
})
</script>

<style lang="scss" scoped>
:deep(.form-control) {
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
  color: #757575;
  padding-left: 16px;
}
.flex-gap {
  gap: 1.5rem;
  flex-wrap: wrap;
}
.flex-pad {
  flex: 0 0 calc(50% - 0.75rem);
}
.client-city {
  width: 390px;
  margin-right: 16px;
}

.mail-service,
.service-name {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #151515;

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 10px;
  }
}

.service-choose {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #151515;
}

.cursor-pointer {
  cursor: pointer;
}

.client-city {
  width: 400px;
  display: block;
  margin-right: 10px;
}

.back-btn {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;

  &-icon {
    margin-right: 10px;
  }

  &-text {
    font-style: normal;
    font-weight: 600;
    color: #151515;
    line-height: 21px;
  }
}

.field-disabled {
  background: rgba(0, 0, 0, 0.05);
}

.action-btn {
  &:hover {
    :deep(span) {
      color: white;
    }
  }

  :deep(span) {
    color: grey;
  }
}

.default-label {
  height: fit-content;
}
</style>
