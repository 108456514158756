/* ============
 * Customers Module
 * ============
 */

import type { ActionContext } from 'vuex'
import type { CustomersStoreState, RootStoreState } from '@/types/Store'
import type { AxiosItem } from '@/types/Components/Response'
import type { Company } from '@/types/Models/Company'
import type { CreateCustomer } from '@/types/Models/Customer'
import type { RegistrationData } from '@/types/Models/Account'
import { none } from '@/services/Helpers'
import integrations from '@/store/modules/customers/integrations'
import senderAddresses from '@/store/modules/customers/senderAddresses'
import CompanyProxy from '@/proxies/CompanyProxy'
import CompanyTransformer from '@/transformers/CompanyTransformer'
import ApiProxy from '@/proxies/ApiProxy'

export const FILL_CUSTOMER = 'FILL_CUSTOMER'
export const FILL_CUSTOMERS = 'FILL_CUSTOMERS'

type Context = ActionContext<CustomersStoreState, RootStoreState>

const state: CustomersStoreState = {
  customer: null,
  allCustomers: [],
}

const getters = {
  get: (state: CustomersStoreState) => state.customer,
  all: (state: CustomersStoreState) => state.allCustomers,
}

const mutations = {
  [FILL_CUSTOMER](state: CustomersStoreState, payload: Company) {
    state.customer = payload
  },
  [FILL_CUSTOMERS](state: CustomersStoreState, payload: Company[]) {
    state.allCustomers = payload.map((customer: Company) => ({
      ...customer,
      label:
        customer.name ||
        (customer.contactName && customer.contactName.length > 1
          ? `${customer.contactName} ${customer.contactLastName}`
          : customer.email),
      name:
        customer.name ||
        (customer.contactName ? `${customer.contactName} ${customer.contactLastName}` : customer.email),
      handle: customer.id,
      status: 'active',
    }))
  },
}

const actions = {
  create: (_: Context, payload: CreateCustomer) => new CompanyProxy().create(payload),
  archive: (_: Context, id: string) => new CompanyProxy().updateField(id, 'isActive', 0),
  unarchive: (_: Context, id: string) => new CompanyProxy().updateField(id, 'isActive', 1),
  update: (_: Context, { id, data }: { id: string; data: any }) =>
    new CompanyProxy().update(id, CompanyTransformer.send(data)),
  requestCreate: (_: Context, payload: RegistrationData) => new ApiProxy().requestCreate(payload),
  fetch: ({ commit }: Context, id: string) => {
    new CompanyProxy()
      .find(id)
      .catch(none)
      .then((response: AxiosItem<Company>) => {
        if (response?.data) {
          commit(FILL_CUSTOMER, response.data)
        }
      })
  },
  fetchAll: ({ commit }: Context) => {
    new CompanyProxy()
      .all()
      .catch(none)
      .then((response) => {
        commit(FILL_CUSTOMERS, response?.data || [])
      })
  },
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
  modules: {
    integrations,
    senderAddresses,
  },
}
