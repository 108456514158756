<template>
  <div>
    <AssignmentStripe />
    <div class="container">
      <div class="assignment">
        <div class="assignment__body">
          <div class="assignment__title">Details</div>

          <div class="row mb-3">
            <div class="col-3">
              <div class="assignment__subtitle mb-1">Distribution channel</div>
              <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
                {{ distributionChannel }}
              </div>
            </div>
            <div class="col-3">
              <div class="assignment__subtitle mb-1">Service level</div>
              <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
                <div>
                  {{ serviceLevel }}
                </div>
                <div v-if="serviceLevelComment">
                  <small>{{ serviceLevelComment }}</small>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="assignment__subtitle mb-1">Assignment reference</div>
              <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
                {{ assignment.reference }}
              </div>
            </div>
            <div class="col-3">
              <div class="assignment__subtitle mb-1">Item (shipment) reference</div>
              <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
                {{ assignment.item?.orderReference }}
              </div>
            </div>
          </div>

          <hr class="delimiter" />

          <div v-if="assignment.pickupDate">
            <div class="assignment__title">Pickup</div>

            <div class="row mb-3">
              <div class="col-3">
                <div class="assignment__subtitle mb-1">Date</div>
                <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
                  {{ formattedDate(assignment.pickupDate, 'MMMM DD') }}
                </div>
              </div>
              <div class="col-3">
                <div class="assignment__subtitle mb-1">Instructions</div>
                <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
                  {{ assignment.pickupInstructions }}
                </div>
              </div>
            </div>

            <hr class="delimiter" />
          </div>

          <div v-if="assignment.senderAddress">
            <div class="assignment__title">Sender</div>
            <div class="row mb-3">
              <div class="col-3">
                <div class="assignment__subtitle mb-1">Name</div>
                <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
                  {{ sender }}
                </div>
              </div>
              <div class="col-9">
                <div class="assignment__subtitle mb-1">Address</div>
                <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
                  {{ toAddressString(assignment.senderAddress) }}
                </div>
              </div>
            </div>

            <hr class="delimiter" />
          </div>

          <div v-if="assignment.recipient">
            <div class="assignment__title">Recipient</div>
            <div class="row mb-3">
              <div class="col-3">
                <div class="assignment__subtitle mb-1">Name</div>
                <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
                  {{ addressOwner(assignment.recipient) }}
                </div>
              </div>
              <div class="col-4">
                <div class="assignment__subtitle mb-1">Address</div>
                <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
                  {{ toAddressString(assignment.recipient) }}
                </div>
              </div>
              <div class="col-2">
                <div class="assignment__subtitle mb-1">Email</div>
                <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
                  {{ assignment.recipient.email }}
                </div>
              </div>
              <div class="col-2">
                <div class="assignment__subtitle mb-1">Phone</div>
                <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
                  {{ assignment.recipient.phoneNumber }}
                </div>
              </div>
            </div>

            <hr class="delimiter" />
          </div>

          <div v-if="!hasCustomInvoice">
            <div class="assignment__title">Customs Invoice</div>

            <div class="d-flex">
              <div class="assignment__subtitle mb-1">Category:</div>
              <div class="assignment__subtitle assignment__subtitle_color-grey mb-1 ml-2">
                {{ invoiceCategory }}
              </div>
            </div>

            <div v-if="products && products.length > 0" class="row mb-3">
              <div class="col-12">
                <table class="table-report bg-grey-title">
                  <thead>
                    <tr>
                      <th>Quantity</th>
                      <th>Country</th>
                      <th>Content (in English)</th>
                      <th>Commodity code</th>
                      <th>Unit weight (g)</th>
                      <th>Unit value</th>
                      <th v-text="`Total value (${currency})`" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(product, index) in products" :key="'product' + index">
                      <td>{{ product.quantity }}</td>
                      <td>{{ product.countryCode }}</td>
                      <td>{{ product.description }}</td>
                      <td>{{ product.commodityCode }}</td>
                      <td>{{ product.weight }}</td>
                      <td>{{ product.estimatedCost }}</td>
                      <td>
                        {{ product.quantity * Number(product.estimatedCost) }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="6" class="border-0" />
                      <td>
                        <div class="total-count">
                          {{ totalInvoiceCosts || 0 }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <hr class="delimiter" />
          </div>

          <div>
            <div class="assignment__title">Package details</div>
            <div class="row mb-5">
              <div class="col-2">
                <div class="assignment__subtitle mb-1">Weight</div>
                <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
                  {{ assignment.item?.weight }} g
                </div>
              </div>
              <div class="col-2">
                <div class="assignment__subtitle mb-1">Height</div>
                <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
                  {{ assignment.item?.height }} cm
                </div>
              </div>
              <div class="col-2">
                <div class="assignment__subtitle mb-1">Length</div>
                <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
                  {{ assignment.item?.length }} cm
                </div>
              </div>
              <div class="col-2">
                <div class="assignment__subtitle mb-1">Width</div>
                <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
                  {{ assignment.item?.width }} cm
                </div>
              </div>
              <div class="col-2">
                <div class="assignment__subtitle mb-1">Worth</div>
                <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
                  {{ assignment.item?.estimatedCost }} kr
                </div>
              </div>
              <div class="col-2">
                <div class="assignment__subtitle mb-1">Description / Ref</div>
                <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
                  {{ assignment.item?.description }}
                </div>
              </div>
            </div>
          </div>

          <hr class="delimiter" />

          <div>
            <div class="assignment__title">Optimisation details</div>
            <div v-if="assignment.item?.carrierHandle" class="row mb-5">
              <div class="col-3">
                <div class="assignment__subtitle mb-1">Carrier</div>
                <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
                  <CarrierRowWithIcon :carrier="assignment.item.carrierHandle" :only-icon="false" />
                </div>
              </div>
              <div v-if="!isHidePrice" class="col-3">
                <div class="assignment__subtitle mb-1">Shipping cost</div>
                <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
                  <ShippingCostCell class="cost" />
                </div>
              </div>
            </div>
          </div>

          <Open v-if="assignment.status === OPEN" />
          <ReportReleased v-if="assignment.status === REPORT_RELEASED" />
          <LabelsReleased v-else-if="assignment.status === LABELS_RELEASED" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { Assignment } from '@/types/Models/Assignment'
import type { SelectOptions } from '@/types/Components/SelectOptions'
import type { Product } from '@/types/Models/Product'
import { useAddressTransformers } from '@/composables/Address/useAddressTransformers'
import { ref, computed, defineComponent, type Ref, type ComputedRef, watch } from 'vue'
import { formattedDate } from '@/services/Helpers'
import { useStore } from 'vuex'
import {
  getDeliveryTypeLabel,
  getDistributionChannelTxt,
  getProformaInvoiceTypes,
  getServiceLevelTxt,
} from '@/services/Utils'
import { COMPLETE, LABELS_RELEASED, OPEN, REPORT_ACCEPTED, REPORT_RELEASED } from '@/const/AssignmentStatuses'
import { LETTER } from '@/const/DistributionChannels'
import { ECONOMY } from '@/const/AssignmentServiceLevels'
import { OTHER } from '@/const/ProformaInvoiceTypes'
import Open from '@/views/Assignments/Details/Single/Actions/Open.vue'
import ShippingCostCell from '@/views/Assignments/Details/Single/ShippingCostCell.vue'
import ReportReleased from '@/views/Assignments/Details/Single/Actions/ReportReleased.vue'
import LabelsReleased from '@/views/Assignments/Details/Single/Actions/LabelsReleased.vue'
import AssignmentStripe from '@/views/Components/Assignment/Stripe/AssignmentStripe.vue'
import CarrierRowWithIcon from '@/views/Components/Assignment/CarrierRowWithIcon.vue'

export default defineComponent({
  name: 'SingleAssignmentDetails',
  components: {
    CarrierRowWithIcon,
    AssignmentStripe,
    Open,
    ShippingCostCell,
    ReportReleased,
    LabelsReleased,
  },
  setup() {
    const store = useStore()

    const assignment: ComputedRef<Assignment> = computed(() => store.getters['assignment/assignmentSummary/get'])
    const isStaff: ComputedRef<boolean> = computed(() => store.getters['account/isStaff'])
    const isHidePrice: ComputedRef<boolean> = computed(() => !isStaff.value && assignment.value?.company?.hidePrice)

    const products: Ref<Product[]> = ref([])
    const showDownloadButton: Ref<boolean> = ref(true)
    const isLabelCreated: Ref<boolean> = ref(false)
    const hasCustomInvoice: Ref<boolean> = ref(false)
    const currency: Ref<string> = ref('SEK')
    const totalInvoiceCosts: Ref<number> = ref(0)
    const documentsUrl: Ref<string | null> = ref(null)

    const serviceLevelComment = computed(() => {
      if (assignment.value.distributionChannel === LETTER) {
        return 'Tracking not available.'
      }

      return assignment.value.serviceLevel === ECONOMY ? '1-4 days, cheapest. Tracking available.' : ''
    })

    const categories: ComputedRef<Record<string, SelectOptions>> = computed(
      getProformaInvoiceTypes as unknown as () => Record<string, SelectOptions>,
    )
    const invoiceCategory: ComputedRef<string | null> = computed(
      () => categories.value[assignment.value.item?.invoiceType || OTHER]?.text || null,
    )

    const distributionChannel: ComputedRef<string> = computed(() =>
      getDistributionChannelTxt(assignment.value.distributionChannel),
    )
    const serviceLevel = computed(() => {
      return getServiceLevelTxt(assignment.value.serviceLevel || null)
    })

    const { toAddressString, addressOwner, addressCompany } = useAddressTransformers()

    const sender: ComputedRef<string> = computed(() => {
      let sa = addressOwner(assignment.value.senderAddress)
      return sa ? sa : addressCompany(assignment.value.company)
    })

    // todo - move to computed
    watch(assignment.value, () => {
      showDownloadButton.value = assignment.value.status !== COMPLETE
      products.value = assignment.value.item?.products ?? []
      isLabelCreated.value = assignment.value.item?.labelsCreationStatus !== 'pending'
      hasCustomInvoice.value = assignment.value.item?.products?.length === 0
      documentsUrl.value = assignment.value.documentsUrl
      currency.value = assignment.value.item?.currency ?? 'EUR'
      totalInvoiceCosts.value =
        assignment.value.item?.products?.reduce(
          (acc: number, cv: Product) => acc + cv.quantity * parseInt(cv.estimatedCost),
          0,
        ) || 0
    })

    return {
      sender,
      assignment,
      products,
      showDownloadButton,
      isLabelCreated,
      hasCustomInvoice,
      currency,
      totalInvoiceCosts,
      documentsUrl,
      isHidePrice,
      serviceLevelComment,
      invoiceCategory,
      distributionChannel,
      serviceLevel,
      getDeliveryTypeLabel,
      OPEN,
      LABELS_RELEASED,
      REPORT_ACCEPTED,
      REPORT_RELEASED,
      toAddressString,
      addressOwner,
      formattedDate,
    }
  },
})
</script>

<style lang="scss" scoped>
.centred {
  text-align: center;
}
.assignment {
  &__body {
    background: #ffffff;
    padding: 40px;
    margin-bottom: 128px;
  }

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    text-transform: capitalize;
    color: #151515;
    margin-bottom: 24px;
  }

  &__subtitle {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    color: #757575;
    margin-bottom: 40px;

    &_color-grey {
      color: #151515;
    }

    &_color-black {
      font-style: normal;
      font-size: 16px;
      line-height: 150%;
      color: #151515;
      mix-blend-mode: normal;
    }
  }

  &-header {
    margin-bottom: 24px;

    &__title {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 150%;
      text-transform: capitalize;
      color: #151515;
    }

    &__subtitle {
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 150%;
      color: #151515;
    }
  }

  .form-group {
    pointer-events: none;
    cursor: none;
    margin-bottom: 0;

    &.disabled {
      .radio-group {
        &::before {
          filter: grayscale(2);
          opacity: 0.6;
        }
      }
    }

    .radio-group {
      border: none !important;
      background: none !important;
      padding-left: 30px !important;
      padding-top: 0;
      padding-bottom: 0;
      margin: 0 0 40px 0;
      filter: grayscale(1);

      &.hide-radio {
        padding-left: 0 !important;

        &::before {
          display: none;
        }
      }

      &::before {
        top: -1px;
        left: 0;
      }
    }
  }

  .delimiter {
    height: 1px;
    background: #d6d6d1;
    margin: 0 0 40px 0;
  }

  .saved-cost {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    span {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 150%;
      color: #08a762;
    }

    &__percent {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;
      background: rgba(8, 167, 98, 0.15);
      border-radius: 32px;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 144%;
      color: #08a762;
      padding: 0 5px;
      margin-left: 8px;
    }
  }
}

.cost {
  :deep(.saved-cost__value) {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    /* identical to box height, or 27px */

    /* Green - Success */
    color: #08a762;
  }
}

.table-report {
  thead {
    th {
      font-size: 13px;
    }
  }
}
</style>
