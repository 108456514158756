<template>
  <div id="customers">
    <ActiveCompanyTable :v-table-name="TABLE_NAME_CUSTOMERS_ACTIVE" :refresh="refreshTable" />
    <EditCompanyGeneralInfoModal :id="EDIT_COMPANY_GENERAL_INFO_MODAL" @update="doRefresh" />
    <EditCompanyAddressModal :id="EDIT_COMPANY_ADDRESS_MODAL" @update="doRefresh" />

    <SenderAddressesModal :id="SENDER_ADDRESSES" />
    <SenderAddressActionModal :id="SENDER_ADDRESS_ACTION" />

    <IntegrationsActionModal :id="INTEGRATIONS" />
    <IntegrationsDetailsModal :id="INTEGRATION_DETAILS" />
    <IntegrationsOrdersModal :id="INTEGRATION_ORDERS" />
    <IntegrationsWoocommerceKeysModal :id="WOO_COMMERCE_KEYS" />
    <IntegrationsShopifySettingsModal :id="SHOPIFY_WEBHOOK_SETTINGS" />
    <IntegrationsOngoingSettingsModal :id="ONGOING_WEBHOOK_SETTINGS" />
  </div>
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent, onMounted, ref } from 'vue'
import { registerModals } from '@/services/Modal'
import { TABLE_NAME_CUSTOMERS_ACTIVE } from '@/const/TableNames'
import {
  EDIT_COMPANY_ADDRESS_MODAL,
  EDIT_COMPANY_GENERAL_INFO_MODAL,
  INTEGRATION_DETAILS,
  INTEGRATION_ORDERS,
  INTEGRATIONS,
  ONGOING_WEBHOOK_SETTINGS,
  SENDER_ADDRESS_ACTION,
  SENDER_ADDRESSES,
  SHOPIFY_WEBHOOK_SETTINGS,
  WOO_COMMERCE_KEYS,
} from '@/const/ModalTypes'
import EditCompanyGeneralInfoModal from '@/views/Modals/Customer/EditCompanyGeneralInfoModal.vue'
import EditCompanyAddressModal from '@/views/Modals/Customer/EditCompanyAddressModal.vue'
import SenderAddressesModal from '@/views/Modals/SenderAddress/SenderAddressesModal.vue'
import SenderAddressActionModal from '@/views/Modals/SenderAddress/SenderAddressActionModal.vue'
import IntegrationsActionModal from '@/views/Modals/Integrations/IntegrationsActionModal.vue'
import IntegrationsDetailsModal from '@/views/Modals/Integrations/IntegrationsDetailsModal.vue'
import IntegrationsOrdersModal from '@/views/Modals/Integrations/IntegrationsOrdersModal.vue'
import IntegrationsWoocommerceKeysModal from '@/views/Modals/Integrations/IntegrationsWoocommerceKeysModal.vue'
import IntegrationsShopifySettingsModal from '@/views/Modals/Integrations/IntegrationsShopifySettingsModal.vue'
import IntegrationsOngoingSettingsModal from '@/views/Modals/Integrations/IntegrationsOngoingSettingsModal.vue'

export default defineComponent({
  name: 'CustomersListPage',
  components: {
    SenderAddressActionModal,
    SenderAddressesModal,
    EditCompanyGeneralInfoModal,
    EditCompanyAddressModal,
    IntegrationsActionModal,
    IntegrationsDetailsModal,
    IntegrationsOrdersModal,
    IntegrationsWoocommerceKeysModal,
    IntegrationsShopifySettingsModal,
    IntegrationsOngoingSettingsModal,
    ActiveCompanyTable: defineAsyncComponent(() => import('@/views/Components/Customer/ActiveCompaniesTable.vue')),
  },
  setup() {
    const refreshTable = ref('')
    const doRefresh = () => {
      refreshTable.value = Math.random().toString()
    }
    onMounted(() => {
      registerModals(EDIT_COMPANY_GENERAL_INFO_MODAL)
      registerModals(EDIT_COMPANY_ADDRESS_MODAL)

      registerModals(SENDER_ADDRESSES)
      registerModals(SENDER_ADDRESS_ACTION)

      registerModals(INTEGRATIONS)
      registerModals(INTEGRATION_DETAILS)
      registerModals(WOO_COMMERCE_KEYS)
      registerModals(SHOPIFY_WEBHOOK_SETTINGS)
      registerModals(ONGOING_WEBHOOK_SETTINGS)
      registerModals(INTEGRATION_ORDERS)
    })

    return {
      doRefresh,
      refreshTable,
      EDIT_COMPANY_GENERAL_INFO_MODAL,
      TABLE_NAME_CUSTOMERS_ACTIVE,
      EDIT_COMPANY_ADDRESS_MODAL,
      ONGOING_WEBHOOK_SETTINGS,
      SHOPIFY_WEBHOOK_SETTINGS,
      SENDER_ADDRESS_ACTION,
      INTEGRATION_DETAILS,
      INTEGRATION_ORDERS,
      WOO_COMMERCE_KEYS,
      SENDER_ADDRESSES,
      INTEGRATIONS,
    }
  },
})
</script>
