// Desc: Priority module for vuex store
import type { PriorityStoreState, RootStoreState } from '@/types/Store'
import type { ActionContext } from 'vuex'
import type { Priority } from '@/types/Models/Priority'
import { unionBy } from '@/services/Utils'
import { ASSIGNMENT, CUSTOMER, SENDER_ADDRESS } from '@/const/PriorityTypes'
import CompanyPriorityProxy from '@/proxies/Priority/CompanyPriorityProxy'
import AssignmentPriorityProxy from '@/proxies/Priority/AssignmentPriorityProxy'
import SenderAddressPriorityProxy from '@/proxies/Priority/SenderAddressPriorityProxy'
import BasePriorityProxy from '@/proxies/Priority/BasePriorityProxy'

export const SET_PRIORITIES = 'SET_PRIORITIES'
export const UPDATE_PRIORITIES = 'UPDATE_PRIORITIES'
export const RESET_PRIORITIES = 'RESET_PRIORITIES'
export const RESET_STATE = 'RESET_STATE'
export const SET_TYPE = 'SET_TYPE'

const state: PriorityStoreState = {
  priorities: [],
  changed: [],
  type: '',
}

type Context = ActionContext<PriorityStoreState, RootStoreState>

const getters = {
  all(state: PriorityStoreState) {
    return state.priorities
  },
  changed(state: PriorityStoreState) {
    return state.changed
  },
}

const mutations = {
  [SET_PRIORITIES](state: PriorityStoreState, payload: Priority[]) {
    state.priorities = payload
  },
  [UPDATE_PRIORITIES](state: PriorityStoreState, payload: Priority[]) {
    state.priorities = unionBy(payload, state.priorities, 'id')
    state.changed = unionBy(payload, state.changed, 'id')
  },
  [RESET_PRIORITIES](state: PriorityStoreState) {
    state.changed = []
  },
  [RESET_STATE](state: PriorityStoreState) {
    state.changed = []
    state.priorities = []
    state.type = ''
  },
  [SET_TYPE](state: PriorityStoreState, type: string) {
    state.type = type
  },
}

const actions = {
  set: ({ commit }: Context, payload: Priority[]) => {
    commit(UPDATE_PRIORITIES, payload)
  },
  reset: async ({ commit, state }: Context, id: string) => {
    const { type } = state
    switch (type) {
      case CUSTOMER:
        await new CompanyPriorityProxy().destroy(id)
        break
      case ASSIGNMENT:
        await new AssignmentPriorityProxy().destroy(id)
        break
      case SENDER_ADDRESS:
        await new SenderAddressPriorityProxy().destroy(id)
        break
      default:
        break
    }
    commit(RESET_STATE)
  },
  fetch: async ({ commit, state }: Context, id = '') => {
    const { type } = state
    let result
    switch (type) {
      case CUSTOMER:
        result = await new CompanyPriorityProxy().find(id)
        break
      case ASSIGNMENT:
        result = await new AssignmentPriorityProxy().find(id)
        break
      case SENDER_ADDRESS:
        result = await new SenderAddressPriorityProxy().find(id)
        break
      default:
        result = await new BasePriorityProxy().all()
    }
    commit(SET_PRIORITIES, result.data)
    return result.data
  },
  save: async ({ commit, state }: Context, id: string) => {
    const { type, priorities } = state

    switch (type) {
      case CUSTOMER:
        await new CompanyPriorityProxy().update(id, { priorities })
        break
      case ASSIGNMENT:
        await new AssignmentPriorityProxy().update(id, { priorities })
        break
      case SENDER_ADDRESS:
        await new SenderAddressPriorityProxy().update(id, { priorities })
        break
      default:
        await new BasePriorityProxy().update(id, { priorities })
    }
    commit(RESET_STATE)
  },
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}
