/* ============
 * Company Transformer
 * ============
 *
 * The transformer for the account.
 */

import type { Company, RequestCompany } from '@/types/Models/Company'

export default class CompanyTransformer {
  /**
   * Method used to transform a fetched company.
   */
  public static fetch(company: Company): Company {
    return {
      ...company,
      salesman: company.salesman || {},
      salesmanId: company.salesman?.id || null,
    }
  }

  /**
   * Method used to transform a send company data.
   */
  static send(company: Company): RequestCompany {
    return { ...company }
  }

  /**
   * Method used to transform a fetched collection.
   */
  static fetchCollection(items: Company[]) {
    return items.map((item) => this.fetch(item))
  }
}
