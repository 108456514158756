import type { IntegrationsStoreState, RootStoreState } from '@/types/Store'
import type { ActionContext } from 'vuex'
import type { AxiosCollection, AxiosErrors } from '@/types/Components/Response'
import type { Integration } from '@/types/Models/Integration'
import IntegrationsProxy from '@/proxies/Customer/IntegrationsProxy'
import IntegrationsTransformer from '@/transformers/IntegrationsTransformer'

export const INIT_INTEGRATION = 'INIT_INTEGRATION'

type Context = ActionContext<IntegrationsStoreState, RootStoreState>

const state: IntegrationsStoreState = {
  integrations: [],
}

const getters = {
  get(state: IntegrationsStoreState) {
    return state.integrations
  },
}

const mutations = {
  [INIT_INTEGRATION](state: IntegrationsStoreState, payload: Integration[]) {
    state.integrations = payload
  },
}

const actions = {
  init: (_: Context, { customerId, system }: { customerId: string; system: string }) =>
    new Promise((resolve, reject) => {
      new IntegrationsProxy(customerId)
        .initConnection(system)
        .then((res) => resolve(res))
        .catch((e: AxiosErrors) => reject(e))
    }),
  fetch: ({ commit }: Context, customerId: string) =>
    new Promise((resolve, reject) => {
      new IntegrationsProxy(customerId)
        .all()
        .then((response: AxiosCollection<Integration>) => {
          commit(INIT_INTEGRATION, IntegrationsTransformer.fetchCollection(response.data))
          resolve(response)
        })
        .catch((e) => {
          reject(e)
        })
    }),
  destroy: (_: Context, { customerId, integrationId }: { customerId: string; integrationId: string }) =>
    new Promise((resolve, reject) => {
      new IntegrationsProxy(customerId)
        .destroy(integrationId)
        .then(() => resolve(null))
        .catch((e: AxiosErrors) => reject(e))
    }),
  connectShopify: (_: Context, { customerId, token }: { customerId: string; token: string }) =>
    new Promise((resolve, reject) => {
      new IntegrationsProxy(customerId)
        .connectShopify(token)
        .then((res) => resolve(res))
        .catch((e: AxiosErrors) => reject(e))
    }),
  connectOnGoing: (
    _: Context,
    { customerId, token, externalId }: { customerId: string; token: string; externalId: string },
  ) =>
    new Promise((resolve, reject) => {
      new IntegrationsProxy(customerId)
        .connectOngoing(token, externalId)
        .then((res) => resolve(res))
        .catch((e: AxiosErrors) => reject(e))
    }),
  connectWoocommerce: (
    _: Context,
    { customerId, credentials, options }: { customerId: string; credentials: any; options: any },
  ) =>
    new Promise((resolve, reject) => {
      new IntegrationsProxy(customerId)
        .connectWoocommerce(credentials, options)
        .then((res) => resolve(res))
        .catch((e: AxiosErrors) => reject(e))
    }),
  runActions: (
    _: Context,
    { customerId, id, action, params }: { customerId: string; id: string; action: string; params: any },
  ) =>
    new Promise((resolve, reject) => {
      new IntegrationsProxy(customerId)
        .runActions(id, action, params)
        .then((res) => resolve(res))
        .catch((e: AxiosErrors) => reject(e))
    }),
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}
