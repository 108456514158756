import type { Reactive, Ref } from 'vue'
import type { AssignmentCreationData, ErrorsList } from '@/types/Models/Assignment'
import terminalAddressInfo from '@/assets/files/terminalAddressInfo.json'

type Props = {
  assignment: Ref<AssignmentCreationData>
  errorsList: Reactive<ErrorsList>
}

export const useSetterShortcuts = ({ assignment, errorsList }: Props) => {
  const onCityUpdate = (value: string) => {
    assignment.value.item.city = value
  }

  const onCountryUpdate = (value: string) => {
    assignment.value.item.country_code = value
  }

  const selectSenderAddress = (value: string) => {
    assignment.value.sender_address_id = value
    errorsList.sender_address_id = ''
  }

  const chooseClient = (client: string) => {
    assignment.value.company_id = client
    errorsList.company_id = ''
  }

  const fillTerminalAddress = () => {
    assignment.value.item.company_name = terminalAddressInfo.company_name
    assignment.value.item.first_name = terminalAddressInfo.first_name
    assignment.value.item.email = terminalAddressInfo.email
    assignment.value.item.phone_number = terminalAddressInfo.phone_number
    assignment.value.item.country_code = terminalAddressInfo.country_code
    assignment.value.item.city = terminalAddressInfo.city
    assignment.value.item.postal_code = terminalAddressInfo.postal_code
    assignment.value.item.address = terminalAddressInfo.address
  }

  const companyNameChange = (value: string) => {
    const companyName = value ? value.trim() : ''
    assignment.value.item.is_b2b_shipment = companyName !== '' && companyName.length > 2
  }

  return {
    onCityUpdate,
    onCountryUpdate,
    selectSenderAddress,
    chooseClient,
    fillTerminalAddress,
    companyNameChange,
  }
}
