<template>
  <modal-wrap
    :name="modalName"
    :header-class="'packing-slip-items-header'"
    body-class="packing-slip-items-modal"
    :hide-footer="true"
    size="md"
  >
    <template #modal-title>
      <div class="d-flex align-items-center">
        <mdicon name="CogOutline" size="22" class="dropdown-green" />
        <h3 class="ml-2 mb-0">Sender Addresses</h3>
      </div>
    </template>
    <template #default>
      <div v-if="!addresses.length">
        <h3 class="center-text">Create a new Sender address</h3>
        <div class="d-flex justify-content-center mt-3">
          <button class="btn btn-green" @click="openSenderAddressCreateModal">
            <mdicon name="plus" size="14" class="dropdown-green" />
            Create New
          </button>
        </div>
      </div>
      <div v-else>
        <button class="btn btn-green" @click="openSenderAddressCreateModal">
          <mdicon name="plus" size="14" class="dropdown-green" />
          Create New
        </button>
        <div class="addresses">
          <div
            v-for="address in addresses"
            :id="'sender-address' + address.id"
            :key="address.id"
            :ref="'sender-address' + address.id"
            class="address"
            @mouseover="showActionButtons(address.id)"
            @mouseleave="hideActionButtons(address.id)"
          >
            <div class="address-actions">
              <div class="row w-100 m-auto">
                <div class="col-2">
                  <b-button
                    class="d-flex align-items-center justify-content-center w-100 action-btn"
                    variant="outline-secondary"
                    @click="openSenderAddressEditModal(address)"
                  >
                    <mdicon name="pencil-outline" class="pointer" size="24" />
                  </b-button>
                </div>
                <div class="col-2">
                  <b-button
                    class="d-flex align-items-center justify-content-center w-100 action-btn"
                    variant="outline-secondary"
                    @click="destroySenderAddress(address.id)"
                  >
                    <mdicon name="delete-outline" class="pointer" size="24" />
                  </b-button>
                </div>
                <div class="col-2 pr-2">
                  <b-button
                    :id="address.id"
                    class="w-100 d-flex align-items-center justify-content-center action-btn"
                    variant="outline-secondary"
                    @click="setSenderAddressAsDefault(address.id)"
                  >
                    <mdicon name="Cogs" size="24" />
                  </b-button>
                  <b-tooltip :target="address.id" :noninteractive="true" placement="bottom">
                    Set sender address as default
                  </b-tooltip>
                </div>
                <div class="col-4 my-auto">
                  <div class="d-flex flex-column">
                    <b-badge v-if="address.isDefault" class="default-label" variant="success">
                      Default Address
                    </b-badge>
                    <b-badge v-if="address.hasCustomPrices" variant="success" :class="{ 'mt-1': address.isDefault }">
                      Sender prices
                    </b-badge>
                  </div>
                </div>
              </div>
            </div>
            <div class="address-name">
              {{ address.label }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal-wrap>
</template>

<script lang="ts">
import type { Address } from '@/types/Models/Customer'
import type { KeyAccessor } from '@/types/Helpers/Arrays'
import { none } from '@/services/Helpers'
import { useStore } from 'vuex'
import { openModal } from '@/services/Modal'
import { defineComponent, computed, onBeforeUnmount, type ComputedRef } from 'vue'
import { SENDER_ADDRESSES, SENDER_ADDRESS_ACTION } from '@/const/ModalTypes'
import ModalWrap from '@/views/Modals/ModalWrap.vue'

export default defineComponent({
  name: 'SenderAddressesModal',
  components: { ModalWrap },
  setup() {
    const store = useStore()
    const modalName: string = SENDER_ADDRESSES
    const modalData: ComputedRef<KeyAccessor> = computed(() => store.getters['vueNextModal/modalProps'](modalName))
    const addresses: ComputedRef<Address[]> = computed(() => store.getters['customers/senderAddresses/get'])

    const openSenderAddressCreateModal = () => {
      openModal(SENDER_ADDRESS_ACTION, {
        customerId: modalData.value.id,
        canHasCustomerNumbers: modalData.value.canHasCustomerNumbers,
      })
    }

    const openSenderAddressEditModal = (address: Address) => {
      openModal(SENDER_ADDRESS_ACTION, {
        ...address,
        customerId: modalData.value.id,
        canHasCustomerNumbers: modalData.value.canHasCustomerNumbers,
      })
    }

    const showActionButtons = (addressId?: string) => {
      if (!addressId) return
      document.getElementById(`sender-address${addressId}`)?.classList.add('active')
    }

    const hideActionButtons = (addressId?: string) => {
      if (!addressId) return
      document.getElementById(`sender-address${addressId}`)?.classList.remove('active')
    }

    const destroySenderAddress = (addressId?: string) => {
      if (!addressId) return
      openModal('confirmWrap', {
        message: 'The Sender Address will be deleted.',
        title: 'Are you sure you want to delete sender address?',
        okButtonText: 'Delete',
        onConfirm: () => {
          store
            .dispatch('customers/senderAddresses/destroySenderAddress', {
              companyId: modalData.value.id,
              addressId,
            })
            .then(() => {
              store.dispatch('customers/senderAddresses/fetch', modalData.value.id)
            })
            .catch(none)
        },
      })
    }

    const setSenderAddressAsDefault = (addressId?: string) => {
      if (!addressId) return
      store
        .dispatch('customers/senderAddresses/setAsDefaultSenderAddress', {
          companyId: modalData.value.id,
          addressId,
        })
        .then(() => {
          store.dispatch('customers/senderAddresses/fetch', modalData.value.id)
        })
        .catch(none)
    }

    onBeforeUnmount(() => {
      store.dispatch('customers/senderAddresses/resetSenderAddresses').catch(none)
    })

    return {
      modalName,
      modalData,
      addresses,
      openSenderAddressCreateModal,
      openSenderAddressEditModal,
      showActionButtons,
      hideActionButtons,
      destroySenderAddress,
      setSenderAddressAsDefault,
    }
  },
})
</script>

<style lang="scss" scoped>
.addresses {
  margin-top: 15px;
  .address {
    position: relative;
    margin-bottom: 10px;
    padding: 16px;
    background: #ffffff;
    border: 1px solid #d6d6d1;
    &-name {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: #151515;
    }
    &-actions {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      opacity: 0;
      z-index: -100;
      align-items: center;
      width: 100%;
      height: 100%;
      background: white;
    }
    &.active {
      .address-actions {
        opacity: 1;
        z-index: 1;
      }
    }
  }
}
.action-btn {
  &:hover {
    &:deep(span) {
      color: white;
    }
  }
  &:deep(span) {
    color: grey;
  }
}
</style>
