import type { Address, BasicAddressData, Customer } from '@/types/Models/Customer'
import type { Company } from '@/types/Models/Company'

export const useAddressTransformers = () => {
  const toAddressString = (address?: BasicAddressData | Address | null) => {
    if (!address) return ''
    const parts = [
      address.countryCode !== 'SE' ? address.countryCode : null,
      address.postalCode,
      address.city,
      address.addressLine,
      address.addressLine2,
    ]

    return parts.filter(Boolean).join(', ')
  }

  const addressOwner = (address?: Address | Customer | null) => {
    if (!address) return ''
    const personNameParts = [address.firstName, address.lastName]

    const personName = address.fullName || personNameParts.filter(Boolean).join(' ')

    if (address.company && personName) {
      return `${address.company} (${personName})`
    }

    if (address.company) {
      return address.company
    }

    return personName
  }

  const addressCompany = (address?: Company | null) => {
    if (!address) return ''

    const personName = `${address.contactName} ${address.contactLastName}`

    if (address.name && address.contactName) {
      return `${address.name} (${personName})`
    }

    if (address.name) {
      return address.name
    }

    return personName
  }

  return {
    toAddressString,
    addressCompany,
    addressOwner,
  }
}
