<template>
  <div class="col-5 p-0 d-flex justify-content-end">
    <div>
      <b-button class="w-100" variant="success" size="md" :disabled="!enableContinueButton" @click="create">
        Continue
      </b-button>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { defineComponent } from 'vue'
import { openModal } from '@/services/Modal'
import { CREATE_PACKING_SLIP, PACKING_SLIP } from '@/const/ModalTypes'
import { SET_PACKING_SLIP } from '@/store/modules/packingSlip'
import { READY, FAILED, GENERATING } from '@/const/PackingSlipEventTypes'

export default defineComponent({
  name: 'Continue',
  props: {
    enableContinueButton: {
      type: Boolean,
      default: false,
    },
    fromPreviousPackingSlip: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore()

    const create = () => (props.fromPreviousPackingSlip ? createPackingSlipFromPackingSlipItems() : createPackingSlip())

    const createPackingSlipFromPackingSlipItems = () => {
      openModal('confirmWrap', {
        title: 'Create Packing Slip',
        message:
          'You are trying to create a packing slip with items that are already in other packing slips,' +
          'if you continue items will be removed from selected packing slips and files will be re-generated.' +
          'Are you sure you want to continue?',
        onConfirm: createPackingSlip,
      })
    }

    const listenToPackingSlipEvents = (packingSlip: any) => {
      const stopListeningToPackingSlipEvents = () => {
        window.Echo.channel(`App.User.${store.getters['account/userId']}`)
          .stopListening(`.${packingSlip.id}.${GENERATING}`)
          .stopListening(`.${packingSlip.id}.${READY}`)
          .stopListening(`.${packingSlip.id}.${FAILED}`)
      }
      window.Echo.channel(`App.User.${store.getters['account/userId']}`)
        .listen(`.${packingSlip.id}.${GENERATING}`, (data: any) => {
          store.commit(`packingSlip/${SET_PACKING_SLIP}`, data)
        })
        .listen(`.${packingSlip.id}.${READY}`, (data: any) => {
          store.commit(`packingSlip/${SET_PACKING_SLIP}`, data)
          store.dispatch('packingSlip/getPackingSlips')
          stopListeningToPackingSlipEvents()
        })
        .listen(`.${packingSlip.id}.${FAILED}`, (data: any) => {
          store.commit(`packingSlip/${SET_PACKING_SLIP}`, data)
          stopListeningToPackingSlipEvents()
        })
    }

    const createPackingSlip = () => {
      const data: any = {}
      if (store.getters['packingSlip/getCheckedItems'].length > 0) {
        data.items = store.getters['packingSlip/getCheckedItems']
      } else {
        data.assignments = store.getters['packingSlip/getCheckedAssignments']
      }
      if (store.getters['account/isStaff']) {
        data.companyId = store.getters['packingSlip/getCompanyId']
      }
      store.dispatch('packingSlip/create', data).then(() => {
        listenToPackingSlipEvents(store.getters['packingSlip/getPackingSlip'])
      })
      store.dispatch('vueNextModal/closeModal', CREATE_PACKING_SLIP)
      openModal(PACKING_SLIP)
    }

    return {
      create,
      createPackingSlip,
      createPackingSlipFromPackingSlipItems,
    }
  },
})
</script>
