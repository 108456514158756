<template>
  <div>
    <div class="w-100 d-flex justify-content-between mb-3 filters">
      <TableTitle icon="archive-outline" title="Pickups" />
      <Search @search-triggered="updateSearch" />
    </div>
    <easy-data-table
      ref="dataTable"
      v-model:server-options="serverOptions"
      table-class-name="vue3-easy-data-table table-shadow"
      :server-items-length="serverItemsLength"
      :loading="loading"
      :headers="headers"
      :items="items"
      :prevent-context-menu-row="false"
      :fixed-header="false"
      no-hover
      alternating
      hide-footer
      @update:server-options="updateServerOptions"
    >
      <template #loading>
        <div style="text-align: center">Loading...</div>
      </template>
      <template #no-data>
        <div style="text-align: center">No data available</div>
      </template>
      <template #item-user="{ company }: Pickup">
        {{ company.shortName || company.name }}
      </template>
      <template #item-senderAddress="{ senderAddress, instructions }: Pickup">
        <div>
          {{ toAddressString(senderAddress) }}
        </div>
        <div v-if="instructions">
          <small class="text-muted">{{ instructions }}</small>
        </div>
      </template>
      <template #item-assignment="{ assignments }: Pickup">
        <div v-for="assignment in assignments" :key="assignment.id">
          <router-link :to="getDetailsRoute(assignment)" class="pointer text-light-green">
            {{ assignment.reference }}
          </router-link>
        </div>
      </template>
      <template #item-carrier="{ carrier }: Pickup">
        <CarrierRowWithIcon :carrier="carrier" />
      </template>
      <template #item-pickupDate="{ pickupDate }: Pickup">
        <RowDate :date="pickupDate" :display-time="false" />
      </template>
      <template #item-createdAt="{ createdAt }: Pickup">
        <RowDate :date="createdAt" />
      </template>
    </easy-data-table>

    <TablePagination
      v-model:server-options-ref="serverOptions"
      v-model:data-table-ref="dataTable"
      :server-items-length="serverItemsLength"
    />
  </div>
</template>

<script lang="ts">
import type { Pickup } from '@/types/Models/Pickup'
import { useRoute } from 'vue-router'
import { defineComponent, ref, watch, type Ref, onMounted } from 'vue'
import { useAddressTransformers } from '@/composables/Address/useAddressTransformers'
import { useDetailsRoute } from '@/composables/Assignment/useDetailsRoute'
import Search from '@/views/Components/PageParts/Search.vue'
import TableTitle from '@/views/Components/Elements/Table/TableTitle.vue'
import TablePagination from '@/views/Components/Elements/Table/TablePagination.vue'
import CarrierRowWithIcon from '@/views/Components/Assignment/CarrierRowWithIcon.vue'
import RowDate from '@/views/Components/Elements/Table/RowDate.vue'
import PickupTransformer from '@/transformers/PickupTransformer'
import PickupProxy from '@/proxies/PickupProxy'
import EasyDataTable, { type ServerOptions } from 'vue3-easy-data-table'

export default defineComponent({
  name: 'PickupsTable',
  components: {
    EasyDataTable,
    CarrierRowWithIcon,
    TablePagination,
    TableTitle,
    RowDate,
    Search,
  },
  setup() {
    const route = useRoute()

    const { getDetailsRoute } = useDetailsRoute()

    const dataTable = ref(null)
    const items: Ref<Pickup[]> = ref([])
    const error = ref(false)
    const search = ref('')
    const loading = ref(false)
    const serverItemsLength = ref(0)
    const serverOptions: Ref<ServerOptions> = ref({
      page: 1,
      rowsPerPage: 25,
    })
    const headers = [
      { text: 'User', value: 'user' },
      { text: 'Address, instructions', value: 'senderAddress' },
      { text: 'Assignment', value: 'assignment' },
      { text: 'Status', value: 'status' },
      { text: 'Carrier', value: 'carrier' },
      { text: 'Identifier', value: 'identifier' },
      { text: 'Pickup date', value: 'pickupDate' },
      { text: 'Created at', value: 'createdAt' },
    ]

    const loadFromServer = async () => {
      loading.value = true
      const params = {
        page: serverOptions.value.page,
        limit: serverOptions.value.rowsPerPage,
        ascending: 1,
        byColumn: 0,
        query: search.value,
      }
      new PickupProxy({ ...params, ...route.query })
        .all()
        .then((response) => {
          items.value = PickupTransformer.fetchCollection(response.data || [])
          serverItemsLength.value = response.count || 0
          loading.value = false
          return response
        })
        .catch(() => {
          serverItemsLength.value = 0
          loading.value = false
          items.value = []
          error.value = true
        })
    }

    const { toAddressString } = useAddressTransformers()

    const updateServerOptions = (newOptions: any) => {
      serverOptions.value = newOptions
    }

    const updateSearch = (value: string) => {
      search.value = value
    }

    onMounted(loadFromServer)

    watch(serverOptions, loadFromServer, { deep: true })
    watch(search, loadFromServer, { deep: true })

    return {
      dataTable,
      items,
      error,
      loading,
      headers,
      serverItemsLength,
      serverOptions,
      updateSearch,
      updateServerOptions,
      toAddressString,
      getDetailsRoute,
    }
  },
})
</script>

<style lang="scss" scoped>
.carrier-icon-wrap {
  white-space: nowrap;
}
.carrier-cell {
  padding: 0;
}
</style>
