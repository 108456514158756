<template>
  <div>
    <div class="table-tabs pb-5">
      <b-tabs class="pb-0 pt-0">
        <b-tab v-if="assignment.domesticItemsCount > 0" @click="setActiveTab()">
          <template #title>
            <mdicon name="TruckOutline" />
            Domestic shipments
            <span v-if="domesticErrorCount" class="error-label">
              {{ domesticErrorCount }}
            </span>
          </template>
        </b-tab>
        <b-tab
          v-if="assignment.internationalItemsCount > 0"
          :disabled="assignment.internationalItemsCount === 0"
          @click="setActiveTab()"
        >
          <template #title>
            <mdicon name="Earth" />
            International shipments
            <span v-if="internationalErrorCount" class="error-label">
              {{ internationalErrorCount }}
            </span>
          </template>
        </b-tab>
      </b-tabs>

      <div class="pb-5 mb-5">
        <ItemsTable
          :assignment-id="assignment.id"
          :destination-type="destinationType"
          :version="itemsVersion"
          :filter="searchData"
        />
      </div>

      <div v-if="assignment.domesticItemsCount || assignment.internationalItemsCount" class="tabs-filter pt-0">
        <SearchItemsBy @search="search" />
        <StripeBtn
          v-if="isActiveDeleting"
          class="only-icon ml-3"
          :disabled="checkedItems.length === 0 || isFinishedStatus"
          @click="deleteCheckedItems"
        >
          <mdicon size="18" name="TrashCanOutline" />
        </StripeBtn>
      </div>
    </div>
    <AssignmentFooter :key="`assignment-footer-${assignment.id}`" :destination-type="destinationType" />
  </div>
</template>

<script lang="ts">
import type { SearchFilter } from '@/types/Models/SearchFilterType'
import type { Assignment } from '@/types/Models/Assignment'
import { defineComponent, ref, computed, watch, type Ref, type ComputedRef } from 'vue'
import { notify } from '@kyvg/vue3-notification'
import { useStore } from 'vuex'
import { none } from '@/services/Helpers'
import { DEFAULT_LOADER } from '@/const/LoadersNames'
import { DOMESTIC, INTERNATIONAL } from '@/const/AssignmentDestinationTypes'
import { CANCELLED, COMPLETE, OPEN, VALIDATION_COMPLETE } from '@/const/AssignmentStatuses'
import { LABELS_CREATED_WITH_ERRORS } from '@/const/LabelsCreatedStatuses'
import SearchItemsBy from '@/views/Components/Assignment/SearchItemsBy.vue'
import StripeBtn from '@/views/Components/Elements/Buttons/StripeBtn.vue'
import AssignmentFooter from '@/views/Components/Assignment/AssignmentFooter.vue'
import ItemsTable from '@/views/Components/Assignment/ItemsTable.vue'

export default defineComponent({
  name: 'BatchItemsArea',
  components: {
    StripeBtn,
    ItemsTable,
    SearchItemsBy,
    AssignmentFooter,
  },
  setup() {
    const store = useStore()

    const searchData: Ref<SearchFilter> = ref({
      query: null,
      byTrackingStatus: null,
    })

    const assignment: ComputedRef<Assignment> = computed(() => store.getters['assignment/assignmentSummary/get'])
    const labelsCreatedStatus = computed(() => store.getters['assignment/assignmentSummary/getLabelsCreatedStatus'])
    const itemsVersion = computed(() => store.getters['assignment/itemsVersion'])
    const checkedItems = computed(() => store.getters['item/checkedItems'])
    const destinationType = ref(assignment.value.domesticItemsCount > 0 ? DOMESTIC : INTERNATIONAL)

    const internationalErrorCount = computed(() => store.state.assignment.internationalErrorsCount)
    const domesticErrorCount = computed(() => store.state.assignment.domesticErrorsCount)
    const isFinishedStatus = computed(
      () => assignment.value.status === COMPLETE || assignment.value.status === CANCELLED,
    )

    const isActiveDeleting = computed(
      () =>
        assignment.value.status === VALIDATION_COMPLETE ||
        assignment.value.status === OPEN ||
        labelsCreatedStatus.value === LABELS_CREATED_WITH_ERRORS,
    )

    const setActiveTab = () => {
      document.querySelectorAll('.nav-tabs button').forEach((el, i) => {
        if (el.classList.contains('active')) {
          destinationType.value = i ? DOMESTIC : INTERNATIONAL
        }
      })
      return true
    }

    const deleteCheckedItems = () => {
      if (!checkedItems.value.length) {
        notify({ group: 'error', text: 'Items not selected' })
        return
      }
      store.dispatch('ui/loaderShow', { name: DEFAULT_LOADER })
      store
        .dispatch('item/deleteCheckedItems', { items: checkedItems.value })
        .then(() => {
          store.dispatch('ui/loaderHide', { name: DEFAULT_LOADER })
          store.dispatch('item/resetCheckedItems')
          store.dispatch('assignment/refreshItems')
          store
            .dispatch('assignment/assignmentSummary/fetch', assignment.value.id)
            .then(() => {
              if (!store.getters['item/errorsOnly']) {
                return
              }
              if (destinationType.value === DOMESTIC && assignment.value.domesticErrorsCount === 0) {
                store.dispatch('item/setErrorsOnly', false)
              }
              if (destinationType.value === INTERNATIONAL && assignment.value.internationalErrorsCount === 0) {
                store.dispatch('item/setErrorsOnly', false)
              }
            })
            .catch(none)
          notify({ group: 'success', text: 'Items was deleted!' })
        })
        .catch(() => {
          store.dispatch('ui/loaderHide', { name: DEFAULT_LOADER })
          notify({ group: 'error', text: "Items wasn't deleted!" })
        })
    }

    const search = (params: SearchFilter) => {
      searchData.value = params
    }

    watch(
      assignment,
      (newAssignment) => {
        if (!newAssignment) {
          return
        }
        const { domesticItemsCount, internationalItemsCount } = newAssignment
        if (internationalItemsCount === 0) {
          destinationType.value = DOMESTIC
        } else if (domesticItemsCount === 0) {
          destinationType.value = INTERNATIONAL
        }
      },
      { deep: true },
    )

    return {
      assignment,
      internationalErrorCount,
      domesticErrorCount,
      destinationType,
      checkedItems,
      searchData,
      itemsVersion,
      isActiveDeleting,
      isFinishedStatus,
      deleteCheckedItems,
      setActiveTab,
      search,
    }
  },
})
</script>

<style lang="scss" scoped>
.table-tabs {
  position: relative;
}
.tabs-filter {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 14px;
  padding-top: 40px;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.error-label {
  margin-left: 18px;
  font-size: 11px;
  position: relative;
}

.error-label:after {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background: #ff4e10;
  border-radius: 50%;
  left: -10px;
  top: 50%;
  margin-top: -5px;
}
:deep(.search-item) {
  input {
    border-left: none;
  }
  .custom-dropdown {
    border-right: 1px solid #cccccc !important;
  }
}
</style>
