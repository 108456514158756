<template>
  <div class="company-name-info">
    <div v-if="assignment.senderAddress?.company">
      <span v-b-tooltip="{ title: assignment.company.name || assignment.company.shortName }">
        {{ assignment.senderAddress.company }}
      </span>
    </div>
    <div v-else-if="assignment.company.shortName">
      <span v-b-tooltip="{ title: assignment.company.name }">
        {{ assignment.company.shortName }}
      </span>
    </div>
    <div v-else>
      {{ assignment.company.name }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, type PropType } from 'vue'
import type { Assignment } from '@/types/Models/Assignment'

export default defineComponent({
  name: 'CustomerCell',
  props: {
    assignment: {
      type: Object as PropType<Assignment>,
      required: true,
    },
  },
  setup(props) {
    const tooltipId = computed(() => `assignment_customer_${props.assignment.id}`)

    return {
      tooltipId,
    }
  },
})
</script>
