<template>
  <div>
    <b-button
      v-b-modal="'api-keys-modal-' + company.id"
      class="p-2 fz-inherit"
      variant="outline-secondary"
      @click="handleOpenApiKeysModal"
    >
      <div class="d-flex align-items-center">
        <mdicon name="Api" class="color-black mr-2" size="36" />
        <span class="color-black">API keys</span>
      </div>
    </b-button>

    <b-modal :id="'api-keys-modal-' + company.id" hide-header hide-footer centered>
      <div v-if="!ui.loading">
        <template v-if="apiKeys.length">
          <ul v-for="apiKey in apiKeys" :key="'apiKey-' + apiKey.id" class="list-group mt-2">
            <li class="list-group-item">
              <div class="d-flex">
                <span class="font-weight-bold">Key: </span>
                <div class="d-flex">
                  <span class="d-inline ml-2 mr-2">
                    {{ apiKey.key }}
                  </span>
                  <copy-to-clipboard :text="apiKey.key">
                    <b-button class="file-btn">
                      <mdicon class="btn-table-action pointer reload-btn" name="ContentCopy" />
                    </b-button>
                  </copy-to-clipboard>
                </div>
              </div>
              <div>
                <span class="font-weight-bold">Status:</span>
                <span class="d-inline ml-2">{{ apiKey.status }}</span>
              </div>
              <div v-if="apiKey.description">
                <span class="font-weight-bold">Description:</span>
                <span class="d-inline ml-2">{{ apiKey.description }}</span>
              </div>
            </li>
          </ul>
        </template>
        <template v-else>
          <div class="text-center">API key has not been created before</div>
          <div class="form-group mt-3">
            <label>Description:</label>
            <VInput
              id="descField"
              v-model="formData.description"
              type="text"
              max="100"
              name="Desc"
              placeholder="Description"
            />
          </div>
          <b-button class="btn create-btn btn-green px-3" @click="submitCreateKeys"> Create API key </b-button>
        </template>
      </div>
    </b-modal>
  </div>
</template>

<script lang="ts">
import type { Company } from '@/types/Models/Company'
import type { ApiKey } from '@/types/Models/ApiKey'
import { notify } from '@kyvg/vue3-notification'
import { defineComponent, type PropType, type Ref, ref } from 'vue'
import ApiKeyProxy from '@/proxies/ApiKeyProxy'
import VInput from '@/views/Components/Elements/Form/VInput.vue'
import CopyToClipboard from '@/views/Components/Elements/CopyToClipboard.vue'

export default defineComponent({
  name: 'ApiKeysModal',
  components: { CopyToClipboard, VInput },
  props: {
    company: {
      type: Object as PropType<Company>,
      required: true,
    },
  },
  setup(props) {
    const apiKeys: Ref<ApiKey[]> = ref([])
    const ui = ref({ loading: true })
    const formData = ref({ description: '' })

    const handleOpenApiKeysModal = () => {
      getApiKeys({ companyId: props.company.id })
    }

    const getApiKeys = async (params: { companyId: string }) => {
      const response = await new ApiKeyProxy(params).getByCompanyId()
      if (!apiKeys.value.length) {
        apiKeys.value.push(...response.data)
      }
      ui.value.loading = false
    }

    const submitCreateKeys = () => {
      const payload: { companyId: string; description?: string } = {
        companyId: props.company.id,
      }
      if (formData.value.description.length !== 0) {
        payload.description = formData.value.description
      }
      new ApiKeyProxy().create(payload).then(() => {
        getApiKeys({ companyId: props.company.id })
        notify({ group: 'success', text: 'Api key was created' })
      })
    }

    return {
      ui,
      apiKeys,
      formData,
      handleOpenApiKeysModal,
      submitCreateKeys,
    }
  },
})
</script>

<style lang="scss" scoped>
.fz-inherit {
  font-size: inherit;
}
:deep(.form-control) {
  border: 1px solid #ccc;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #000;
  mix-blend-mode: normal;
  padding-left: 10px !important;
}
:deep(.date-input) {
  color: #000;
  border: 1px solid #ccc;
}
.file-btn {
  width: 24px;
  height: 24px;
  background: transparent;
  padding: 0;
  border: 0;
  color: $black;
  &:hover {
    background: rgba($beige, 0.5);
  }

  svg {
    width: 16px;
    height: 16px;
    margin: 2px 3px 0 0;
  }

  &:first-child {
    margin-right: 18px;
  }

  &.disabled {
    opacity: 1;
    color: $gray;
  }
}
.color-black {
  color: black;
}
</style>
