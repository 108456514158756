// Type: Vuex module
import type { Assignment, AssignmentResponse, StatusType } from '@/types/Models/Assignment'
import type { AssignmentSummaryStoreState, RootStoreState } from '@/types/Store'
import type { ActionContext } from 'vuex'
import AssignmentProxy from '@/proxies/AssignmentProxy'
import AssignmentTransformer from '@/transformers/AssignmentTransformer'
import AssignmentStatusProxy from '@/proxies/AssignmentStatusProxy'
import { LoadingStatus } from '@/const/LoadingStatus'
import { LABELS_CREATED_SUCCESS, LABELS_CREATED_WITH_ERRORS } from '@/const/LabelsCreatedStatuses'
import { CANCELLED, EDITABLE_STATUSES } from '@/const/AssignmentStatuses'
import { none } from '@/services/Helpers'

export const SET_ASSIGNMENT_DATA = 'SET_ASSIGNMENT_DATA'
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS'
export const SET_ASSIGNMENT_STATUS = 'SET_ASSIGNMENT_STATUS'
export const SET_ERRORS_COUNT = 'SET_ERRORS_COUNT'
export const SET_ASSIGNMENT_LABELS_STATUSES = 'SET_ASSIGNMENT_LABELS_STATUSES'
export const RESET_STATE = 'RESET_STATE'

type Context = ActionContext<AssignmentSummaryStoreState, RootStoreState>

type ErrorsCount = Pick<Assignment, 'totalErrorsCount' | 'internationalErrorsCount' | 'domesticErrorsCount'>

const state: AssignmentSummaryStoreState = {
  loadingStatus: LoadingStatus.PENDING,
  assignment: null,
  labelsStatuses: null,
}

const getters = {
  get: (state: AssignmentSummaryStoreState) => state.assignment,
  id: (state: AssignmentSummaryStoreState) => state.assignment?.id,
  loadingStatus: (state: AssignmentSummaryStoreState) => state.loadingStatus,
  assignmentStatus: (state: AssignmentSummaryStoreState) => state.assignment?.status,
  getId: (state: AssignmentSummaryStoreState) => state.assignment?.id,
  getLabelsSuccessCreatedCount: ({ labelsStatuses }: AssignmentSummaryStoreState) => {
    return labelsStatuses ? Object.values(labelsStatuses).filter((item) => item === 'shipped').length : 0
  },
  getLabelsNotCreatedCount: ({ labelsStatuses }: AssignmentSummaryStoreState) => {
    return labelsStatuses ? Object.values(labelsStatuses).filter((item) => item === 'error').length : 0
  },
  getLabelsCreatedStatus: ({ labelsStatuses }: AssignmentSummaryStoreState) => {
    return labelsStatuses && Object.values(labelsStatuses).some((item) => item === 'error')
      ? LABELS_CREATED_WITH_ERRORS
      : LABELS_CREATED_SUCCESS
  },
  isItemsEditable: (state: AssignmentSummaryStoreState) => {
    const status = state.assignment?.archived ? CANCELLED : state.assignment?.status
    if (!status) {
      return false
    }

    return EDITABLE_STATUSES.includes(status)
  },
  senderName: (state: AssignmentSummaryStoreState) => {
    if (!state.assignment) {
      return ''
    }

    return state.assignment.senderAddress?.company || state.assignment.company?.name || ''
  },
  senderShortName: (state: AssignmentSummaryStoreState) => state.assignment?.company?.shortName || '',
}

const mutations = {
  [SET_ASSIGNMENT_DATA](state: AssignmentSummaryStoreState, data: Assignment) {
    state.assignment = data
  },
  [SET_LOADING_STATUS](state: AssignmentSummaryStoreState, loadingStatus: LoadingStatus) {
    state.loadingStatus = loadingStatus
  },
  [SET_ASSIGNMENT_STATUS](state: AssignmentSummaryStoreState, status: StatusType) {
    if (state.assignment) {
      state.assignment.status = status
    }
  },
  [SET_ERRORS_COUNT](state: AssignmentSummaryStoreState, data: ErrorsCount) {
    if (state.assignment) {
      state.assignment.totalErrorsCount = data.totalErrorsCount
      state.assignment.domesticErrorsCount = data.domesticErrorsCount
      state.assignment.internationalErrorsCount = data.internationalErrorsCount
    }
  },
  [SET_ASSIGNMENT_LABELS_STATUSES](state: AssignmentSummaryStoreState, statuses: Record<string, string>) {
    state.labelsStatuses = statuses
  },
  [RESET_STATE](state: AssignmentSummaryStoreState) {
    state.assignment = null
    state.loadingStatus = LoadingStatus.PENDING
    state.labelsStatuses = null
  },
}

type HandleAssignmentActionOptions = {
  action: (assignmentId?: string) => Promise<any>
  isInitialLoad: boolean
  failOnRejection: boolean
}

const handleAssignmentAction = ({ commit, state }: Context, options: HandleAssignmentActionOptions) => {
  return new Promise((resolve, reject) => {
    if (options.isInitialLoad) {
      commit(SET_LOADING_STATUS, LoadingStatus.LOADING)
    } else {
      if (!state.assignment) {
        reject(new Error('Cannot perform action without assignment'))
        return
      }
    }

    options
      .action(state.assignment?.id)
      .then((response) => {
        const data = AssignmentTransformer.fetch(response.data)
        commit(SET_ASSIGNMENT_DATA, data)
        commit(SET_LOADING_STATUS, LoadingStatus.SUCCESS)
        resolve(data)
      })
      .catch((e) => {
        if (options.failOnRejection) {
          commit(SET_LOADING_STATUS, LoadingStatus.FAILURE)
        }
        reject(e)
      })
  })
}

const actions = {
  fetch: (context: Context, id: string) =>
    handleAssignmentAction(context, {
      action: () => new AssignmentProxy().getAssignmentDetails(id),
      isInitialLoad: true,
      failOnRejection: true,
    }),
  refresh: (context: Context) =>
    handleAssignmentAction(context, {
      action: async (assignmentId) => assignmentId && new AssignmentProxy().getAssignmentDetails(assignmentId),
      isInitialLoad: false,
      failOnRejection: false,
    }),
  fetchStatus: ({ commit }: Context, payload: any) => {
    const id = payload || state.assignment?.id
    if (!id) {
      return
    }

    new AssignmentProxy()
      .getStatus(id)
      .then((response) => {
        if (response.data.status) {
          commit(SET_ASSIGNMENT_STATUS, response.data.status)
        }
      })
      .catch(none)
  },
  fetchLabelsStatuses: async ({ commit, state }: Context, payload: any) => {
    const assignmentId = payload || state.assignment?.id
    if (!assignmentId) {
      throw new Error('Cannot fetch labels statuses without assignment')
    }

    const response = await new AssignmentProxy().getLabelsStatuses(assignmentId)
    if (response.data) {
      commit(SET_ASSIGNMENT_LABELS_STATUSES, response.data)
    }
    return response
  },
  reset: ({ commit }: Context) => {
    commit(RESET_STATE)
  },
  setAssignment: ({ commit }: Context, payload: AssignmentResponse) => {
    commit(SET_ASSIGNMENT_DATA, AssignmentTransformer.fetch(payload.data))
    commit(SET_LOADING_STATUS, LoadingStatus.SUCCESS)
  },
  setAssignmentStatus: ({ commit }: Context, payload: string) => {
    commit(SET_ASSIGNMENT_STATUS, payload)
  },
  setErrorsCount: ({ commit }: Context, payload: ErrorsCount) => {
    commit(SET_ERRORS_COUNT, payload)
  },
  submitProcess: (context: Context) =>
    handleAssignmentAction(context, {
      action: async (assignmentId) => assignmentId && new AssignmentStatusProxy(assignmentId).submitProcess(),
      isInitialLoad: false,
      failOnRejection: false,
    }),
  submitOptimize: (context: Context) =>
    handleAssignmentAction(context, {
      action: async (assignmentId) => assignmentId && new AssignmentProxy().submitOptimize(assignmentId),
      isInitialLoad: false,
      failOnRejection: false,
    }),
  submitComplete: (context: Context) =>
    handleAssignmentAction(context, {
      action: async (assignmentId) => assignmentId && new AssignmentStatusProxy(assignmentId).submitComplete(),
      isInitialLoad: false,
      failOnRejection: false,
    }),
  submitReportAccept: (context: Context) =>
    handleAssignmentAction(context, {
      action: async (assignmentId) => assignmentId && new AssignmentStatusProxy(assignmentId).submitReportAccept(),
      isInitialLoad: false,
      failOnRejection: false,
    }),
  submitReportDeny: (context: Context) =>
    handleAssignmentAction(context, {
      action: async (assignmentId) => assignmentId && new AssignmentStatusProxy(assignmentId).submitReportDeny(),
      isInitialLoad: false,
      failOnRejection: false,
    }),
  createLabels: (context: Context) =>
    handleAssignmentAction(context, {
      action: async (assignmentId) => assignmentId && new AssignmentProxy().createLabels(assignmentId),
      isInitialLoad: false,
      failOnRejection: false,
    }),
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
