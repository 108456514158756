<template>
  <div>
    <b-button v-b-modal="'batch-tier-modal-' + company.id" class="p-2 fz-inherit" variant="outline-secondary">
      <div class="d-flex align-items-center">
        <mdicon name="CogOutline" class="color-black mr-2" size="36" />
        <span class="color-black">Configure</span>
      </div>
    </b-button>

    <b-modal :id="'batch-tier-modal-' + company.id" hide-header hide-footer centered>
      <div class="d-flex justify-content-between align-items-center">
        <span>
          PostNord Batch Tier Optimization
          <span v-if="batchTier" class="text-success"> Enabled </span>
          <span v-else class="text-danger"> Disabled </span>
        </span>
        <b-button v-if="batchTier" variant="danger" class="ml-2" @click="toggleBatchTier"> Disable </b-button>
        <b-button v-else variant="success" class="ml-2" @click="toggleBatchTier"> Enable </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, type PropType } from 'vue'
import { useStore } from 'vuex'
import type { Company } from '@/types/Models/Company'

export default defineComponent({
  name: 'BatchTierModal',
  props: {
    company: {
      type: Object as PropType<Company>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()
    const batchTier = ref(false)

    onMounted(() => {
      batchTier.value = props.company.isBatchTierEnabled
    })

    const toggleBatchTier = async () => {
      try {
        batchTier.value = !batchTier.value
        await store.dispatch('customers/update', {
          id: props.company.id,
          data: {
            isBatchTierEnabled: batchTier.value,
          },
        })
      } catch (e) {
        console.error(e)
      }
    }

    return {
      batchTier,
      toggleBatchTier,
    }
  },
})
</script>
