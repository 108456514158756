<template>
  <div>
    <div class="container mb-5">
      <h2 class="form-title">Create assigment</h2>
      <VeeForm class="assignment-form box-shadow" @submit="submit">
        <Customer
          :customer-id="assignment.company_id"
          :error="errorsList.company_id"
          @update:customer-id="chooseClient"
        />

        <SenderAddress
          :sender-address-id="assignment.sender_address_id"
          :customer-id="assignment.company_id"
          :error="errorsList.sender_address_id"
          @update:sender-address-id="selectSenderAddress"
        />

        <AssignmentType v-model:assignmentType="assignment.assignment_type" />

        <DistributionChannel v-model:distributionChannel="assignment.distribution_channel" />

        <BatchCreateForm
          v-if="assignment.assignment_type === CONSOLIDATE"
          :error="errorsList.file"
          @update:file="(f) => (assignment.file = f)"
        />
        <div v-else>
          <div class="form-group">
            <div class="shipping-address-heading">
              <div class="label required fs-xl">Shipping address</div>
              <b-button class="ship-to-terminal-btn" @click="fillTerminalAddress">Ship to Terminal</b-button>
            </div>

            <div class="row mt-4 shipping-address-form">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Company</label>
                  <span class="company-name-info">
                    <img src="../../../../assets/img/info-circle-outlined.svg" alt="" class="mr-1" />
                    Insert only, if sending to company
                  </span>
                  <VInput
                    v-model="assignment.item.company_name"
                    :rules="{ required: false, min: 3 }"
                    type="text"
                    vid="item.company_name"
                    name="item.company_name"
                    placeholder="Company"
                    @input="companyNameChange"
                  />
                </div>
                <div class="d-flex flex-row">
                  <div class="form-group pl-0 m-0 col-6 pr-12">
                    <label class="required">First Name</label>
                    <VInput
                      v-model="assignment.item.first_name"
                      type="text"
                      :rules="{ required: true }"
                      vid="item.first_name"
                      name="item.first_name"
                      placeholder="First Name *"
                    />
                  </div>
                  <div class="form-group p-0 m-0 col-6">
                    <label>Last Name</label>
                    <VInput
                      v-model="assignment.item.last_name"
                      type="text"
                      :rules="{ permissible: true }"
                      vid="item.last_name"
                      name="item.last_name"
                      placeholder="Last Name"
                    />
                  </div>
                </div>
                <div class="form-group mb-0">
                  <label class="required">Address 1</label>
                  <VInput
                    v-model="assignment.item.address"
                    :rules="{ required: true, min: 3, max: 30 }"
                    type="text"
                    vid="item.address"
                    name="item.address"
                    placeholder="Client Address 1 *"
                  />
                </div>
                <div class="form-group">
                  <label>Address 2</label>
                  <VInput
                    v-model="assignment.item.secondary_address"
                    :rules="{ required: false, min: 3, max: 30 }"
                    type="text"
                    vid="item.secondary_address"
                    name="item.secondary_address"
                    placeholder="Client Address 2"
                  />
                </div>
                <div class="d-flex flex-row">
                  <div class="form-group mb-0">
                    <label class="required">ZIP</label>
                    <VInput
                      v-model="assignment.item.postal_code"
                      type="text"
                      :rules="{ required: true, zip: assignment.item.country_code }"
                      vid="postal_code"
                      name="item.postal_code"
                      placeholder="ZIP *"
                    />
                  </div>
                  <div class="form-group client-city mb-0">
                    <label :class="{ required: true }">City</label>
                    <VInputDatalist
                      id="city"
                      v-model="assignment.item.city"
                      type="text"
                      :list="cities"
                      :rules="{ required: true }"
                      vid="item.city"
                      name="item.city"
                      placeholder="City *"
                      @input="onCityUpdate"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <div class="form-group">
                    <label class="required">Country</label>
                    <CountryPicker
                      :value="assignment.item.country_code"
                      :disabled="false"
                      vinput-id="country"
                      name="item.country_code"
                      vid="country_code"
                      placeholder="Choose country *"
                      :required="true"
                      @change="onCountryUpdate"
                    />
                  </div>
                  <div class="form-group">
                    <label
                      :class="{
                        required: (isSwedenPicked && assignment.item.phone_number.length === 0) || !isSwedenPicked,
                      }"
                    >
                      Email Address
                    </label>
                    <VInput
                      v-model="assignment.item.email"
                      type="text"
                      vid="item.email"
                      name="item.email"
                      :rules="rules.emailRule"
                      :placeholder="
                        (isSwedenPicked && assignment.item.phone_number.length === 0) || !isSwedenPicked
                          ? 'Email address *'
                          : 'Email address'
                      "
                    />
                  </div>
                  <div class="form-group">
                    <label
                      :class="{
                        required: (isSwedenPicked && assignment.item.email.length === 0) || !isSwedenPicked,
                      }"
                    >
                      SMS-Number
                    </label>
                    <VInput
                      v-model="assignment.item.phone_number"
                      type="text"
                      vid="item.phone_number"
                      name="item.phone_number"
                      :rules="rules.phoneRule"
                      :formatter="numbersOnly"
                      :placeholder="
                        (isSwedenPicked && assignment.item.email.length === 0) || !isSwedenPicked
                          ? 'SMS-Number *'
                          : 'SMS-Number'
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Pieces
            v-model:pieces="assignment.item.pieces"
            :distribution-channel="assignment.distribution_channel"
            :outside-eu="outsideEu"
            :recipient-country="recipientCountry"
            :is-business-recipient="isBusinessRecipient"
          />
        </div>

        <ServiceLevel
          v-model:service-level="assignment.service_level"
          :distribution-channel="assignment.distribution_channel"
          :is-sweden-picked="isSwedenPicked"
        />

        <PickupBlock
          v-model:instructions="assignment.pickup_instructions"
          :distribution-channel="assignment.distribution_channel"
          :is-sweden-picked="isSwedenPicked"
          :error="errorsList.pickup_date"
          @update:date="setPickupDate"
        />

        <b-form-group>
          <label>Description</label>
          <VInput
            v-model="assignment.description"
            type="text"
            :rules="rules.description"
            vid="customer_reference"
            name="Description"
            placeholder="Description"
          />
        </b-form-group>
        <b-form-group v-if="assignment.assignment_type !== SINGLE">
          <label>Message</label>
          <textarea v-model="assignment.message" class="form-control" placeholder="Message" rows="5" />
        </b-form-group>
        <b-form-group>
          <label class="fs-xl">Invoice Reference</label>
          <VInput
            v-model="assignment.customer_reference"
            type="text"
            :rules="{ required: false }"
            vid="customer_reference"
            name="customer_reference"
            placeholder="Here you can add a reference for your account administrator or billing administrator"
          />
        </b-form-group>
        <div class="form-group mt-4">
          <b-button type="submit" class="btn create-btn btn-green"> Create assigment </b-button>
        </div>
      </VeeForm>
      <SourceFileErrorsModal :id="CREATE_ASSIGNMENT_SOURCE_FILE_ERRORS" />
    </div>
    <RequestErrorModal :id="REQUEST_ERROR" />
  </div>
</template>

<script lang="ts">
import type { AssignmentCreationData, ErrorsList } from '@/types/Models/Assignment'
import { useValidationRules } from '@/composables/AssignmentForm/useValidationRules'
import { useOnSubmit } from '@/composables/AssignmentForm/useOnSubmit'
import { watchDistributionChannelChange } from '@/composables/AssignmentForm/watchDistributionChannelChange'
import { useDerivedValues } from '@/composables/AssignmentForm/useDerivedValues'
import { useCities } from '@/composables/AssignmentForm/useCities'
import { useSetterShortcuts } from '@/composables/AssignmentForm/useSetterShortcuts'
import { ref, reactive, computed, defineComponent, type ComputedRef, type Ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { openModal, registerModals } from '@/services/Modal'
import { numbersOnly } from '@/services/Helpers'
import { Form as VeeForm } from 'vee-validate'
import { OTHER } from '@/const/ProformaInvoiceTypes'
import { BOX, PACKAGE, PARCEL } from '@/const/DistributionChannels'
import { ADDRESS_DELIVERY, SERVICE_POINT_DELIVERY } from '@/const/AssignmentDeliveryTypes'
import { CONSOLIDATE, SINGLE } from '@/const/AssignmentTypes'
import {
  CREATE_ASSIGNMENT_SOURCE_FILE_ERRORS,
  CREATE_NEW_CUSTOMER,
  REQUEST_ERROR,
  SENDER_ADDRESS_ACTION,
} from '@/const/ModalTypes'
import { defaultPiece } from '@/composables/Pieces/defaultPiece'
import VInput from '@/views/Components/Elements/Form/VInput.vue'
import CountryPicker from '@/views/Components/Elements/Form/CountryPicker.vue'
import VInputDatalist from '@/views/Components/Elements/Form/VInputDatalist.vue'
import RequestErrorModal from '@/views/Modals/RequestErrorModal.vue'
import SourceFileErrorsModal from '@/views/Modals/Assignment/SourceFileErrorsModal.vue'
import Pieces from '@/views/Components/Assignment/Pieces/Pieces.vue'
import Customer from '@/views/Components/Assignment/CreateEditForm/Customer.vue'
import SenderAddress from '@/views/Components/Assignment/CreateEditForm/SenderAddress.vue'
import AssignmentType from './AssignmentType.vue'
import DistributionChannel from '@/views/Components/Assignment/CreateEditForm/DistributionChannel.vue'
import ServiceLevel from '@/views/Components/Assignment/CreateEditForm/ServiceLevel.vue'
import BatchCreateForm from '@/views/Components/Assignment/CreateEditForm/Batch/Batch.vue'
import PickupBlock from '@/views/Components/Assignment/CreateEditForm/Pickup.vue'

export default defineComponent({
  name: 'CreateEditForm',
  components: {
    PickupBlock,
    BatchCreateForm,
    ServiceLevel,
    DistributionChannel,
    AssignmentType,
    SenderAddress,
    Customer,
    Pieces,
    VeeForm,
    VInput,
    CountryPicker,
    VInputDatalist,
    SourceFileErrorsModal,
    RequestErrorModal,
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const assignment: Ref<AssignmentCreationData> = ref({
      pickup_date: '',
      pickup_instructions: '',
      file: null,
      company_id: '',
      sender_address_id: '',
      service_level: 'priority',
      description: '',
      message: '',
      customer_reference: '',
      distribution_channel: PACKAGE,
      assignment_type: SINGLE,
      item: {
        is_b2b_shipment: false,
        company_name: '',
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        country_code: '',
        city: '',
        postal_code: '',
        address: '',
        secondary_address: '',
        delivery_type: '',
        weight: '',
        height: '',
        length: '',
        width: '',
        reference: '',
        estimated_cost: '',
        invoiceType: OTHER,
        currency: 'SEK',
        pieces: [defaultPiece],
      },
    })

    const errorsList = reactive<ErrorsList>({
      company_id: '',
      file: '',
      sender_address_id: '',
      pickup_date: false,
    })

    const isStaff: ComputedRef<boolean> = computed(() => store.getters['account/isStaff'])

    const { outsideEu, recipientCountry, isBusinessRecipient, isSwedenPicked } = useDerivedValues({
      assignment,
    })

    const rules = useValidationRules({ assignment, isSwedenPicked })

    const cities = useCities()

    const submit = useOnSubmit({
      assignment,
      isStaff,
      errorsList,
    })

    const setPickupDate = (date: string) => {
      assignment.value.pickup_date = date
    }

    const { onCityUpdate, onCountryUpdate, selectSenderAddress, chooseClient, fillTerminalAddress, companyNameChange } =
      useSetterShortcuts({ assignment, errorsList })

    watchDistributionChannelChange({ assignment })

    onMounted(() => {
      registerModals(REQUEST_ERROR)
      registerModals(CREATE_NEW_CUSTOMER)
      registerModals(SENDER_ADDRESS_ACTION)
      registerModals(CREATE_ASSIGNMENT_SOURCE_FILE_ERRORS)

      assignment.value.assignment_type =
        router.currentRoute.value.query?.assignmentType === SINGLE ? SINGLE : CONSOLIDATE
    })

    return {
      cities,
      submit,
      isStaff,
      openModal,
      numbersOnly,
      onCityUpdate,
      chooseClient,
      setPickupDate,
      onCountryUpdate,
      selectSenderAddress,
      fillTerminalAddress,
      companyNameChange,
      rules,
      errorsList,
      assignment,
      isSwedenPicked,
      outsideEu,
      BOX,
      PARCEL,
      PACKAGE,
      SINGLE,
      CONSOLIDATE,
      ADDRESS_DELIVERY,
      SERVICE_POINT_DELIVERY,
      CREATE_ASSIGNMENT_SOURCE_FILE_ERRORS,
      REQUEST_ERROR,
      recipientCountry,
      isBusinessRecipient,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/parts/assignment_form.scss';
</style>
