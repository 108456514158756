<template>
  <div>
    <div class="d-flex align-items-center mb-4">
      <router-link :to="customerPage" class="mr-2">
        <a class="back-to">
          <mdicon name="ChevronLeft" />
        </a>
      </router-link>
      <h3 class="m-0">
        Prices for customer {{ customer?.name || `${customer?.contactName} ${customer?.contactLastName} ` }}
      </h3>
    </div>
    <div class="d-flex justify-content-between mb-4">
      <StripeBtn @click="openModal(carrierPriorityModal)"> Available carriers </StripeBtn>
      <div class="d-flex align-items-center justify-content-end">
        <span class="d-inline-block mr-2">Reset everything to default</span>
        <StripeBtn @click="resetEverything"> Reset </StripeBtn>
      </div>
    </div>
    <BasePriceTable :prices="prices" :default-prices="defaultPrices" @update-price="updatePrice" />
    <PageFooter @save-prices="savePrices" />
    <CarrierPriorityModal :id="carrierPriorityModal" :entity-id="customerId" type="customer" />
  </div>
</template>

<script lang="ts">
import type { Company } from '@/types/Models/Company'
import type { BasePrice } from '@/types/Models/BasePrice'
import type { RouterLink } from '@/types/Components/Router'
import { none } from '@/services/Helpers'
import { notify } from '@kyvg/vue3-notification'
import { useStore } from 'vuex'
import { registerModals, openModal } from '@/services/Modal'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'
import {
  defineComponent,
  computed,
  ref,
  onBeforeMount,
  onMounted,
  defineAsyncComponent,
  type Ref,
  type ComputedRef,
} from 'vue'
import { CARRIER_PRIORITY_MODAL } from '@/const/ModalTypes'
import { STAFF_CUSTOMERS_LIST } from '@/types/router'
import { DEFAULT_LOADER } from '@/const/LoadersNames'
import { CUSTOMER } from '@/const/PriceTypes'
import StripeBtn from '@/views/Components/Elements/Buttons/StripeBtn.vue'
import CarrierPriorityModal from '@/views/Modals/Assignment/CarrierPriorityModal.vue'

export default defineComponent({
  name: 'CustomerPrices',
  components: {
    PageFooter: defineAsyncComponent(() => import('@/views/Components/PageParts/PageFooter.vue')),
    BasePriceTable: defineAsyncComponent(() => import('@/views/Staff/BasePrice/BasePriceTable.vue')),
    StripeBtn,
    CarrierPriorityModal,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const defaultPrices: Ref<BasePrice[]> = ref([])

    const prices: ComputedRef<BasePrice[]> = computed(() => store.getters['prices/all'])
    const changedPrices: ComputedRef<BasePrice[]> = computed(() => store.getters['prices/changedPrices'])
    const customer: ComputedRef<Company | null> = computed(() => store.getters['customers/get'])
    const customerId: ComputedRef<string> = computed(() => String(route.params.id))
    const carrierPriorityModal: string = CARRIER_PRIORITY_MODAL + 'customer'
    const customerPage: RouterLink = { name: STAFF_CUSTOMERS_LIST }

    onBeforeMount(() => {
      store.dispatch('customers/fetch', customerId.value).catch(none)
      store.commit('prices/SET_TYPE', CUSTOMER)
      store
        .dispatch('prices/fetchDefault', customerId.value)
        .then((res) => {
          defaultPrices.value = res
          store
            .dispatch('prices/fetch', customerId.value)
            .then((result) => {
              if (result.length === 0) {
                store.commit('prices/SET_PRICES', defaultPrices.value)
              }
            })
            .catch(none)
        })
        .catch(none)
    })

    onMounted(() => {
      registerModals(carrierPriorityModal)
    })

    onBeforeRouteLeave((_to, _from, next) => {
      if (changedPrices.value.length > 0) {
        openModal('confirmWrap', {
          title: 'There are unsaved changes',
          message: 'Are you sure you want to cancel?',
          onConfirm: () => {
            store.dispatch('prices/reset')
            next(true)
          },
          onCancel: () => {
            next(false)
          },
        })
      } else {
        next()
      }
    })

    const updatePrice = (price: any) => {
      store.dispatch('prices/updatePrices', price)
    }

    const savePrices = () => {
      store.dispatch('ui/loaderShow', { name: DEFAULT_LOADER })
      store
        .dispatch('prices/save', customerId.value)
        .then(() => {
          store.dispatch('ui/loaderHide', { name: DEFAULT_LOADER })
          notify({ group: 'success', text: 'Prices have been updated' })
        })
        .catch(() => {
          store.dispatch('ui/loaderHide', { name: DEFAULT_LOADER })
          notify({ group: 'error', text: 'Price update error' })
        })
    }

    const resetEverything = () => {
      openModal('confirmWrap', {
        title: 'Reset custom prices',
        message: 'Are you sure you want to reset everything to default?',
        onConfirm: () => {
          store.dispatch('prices/reset', customerId.value)
          router.push(customerPage)
        },
      })
    }

    return {
      prices,
      customer,
      customerId,
      customerPage,
      defaultPrices,
      carrierPriorityModal,
      resetEverything,
      updatePrice,
      savePrices,
      openModal,
    }
  },
})
</script>
