/* ============
 * Account Transformer
 * ============
 *
 * The transformer for the account.
 */

import type { Account } from '@/types/Models/Account'

export default class AccountTransformer {
  /**
   * Method used to transform a fetched account.
   */
  static fetch(account: Account): Account {
    return {
      id: account.id,
      companyId: account.companyId,
      type: account.type,
      firstName: account.firstName,
      lastName: account.lastName,
      email: account.email,
      isScannerOnlyEnabled: account.isScannerOnlyEnabled || false,
    }
  }
}
