// auth.ts
import type { ActionContext } from 'vuex'
import type { AuthData, CreatePasswordData, TokenData } from '@/types/Models/Account'
import type { ForgotPasswordData } from '@/types/Models/Account'
import type { AuthStoreState, RootStoreState } from '@/types/Store'
import { none } from '@/services/Helpers'
import { LOGIN_PAGE } from '@/types/router'
import router from '@/router'
import AuthProxy from '@/proxies/AuthProxy'
import Axios from '@/services/Axios'
import CompanyProxy from '@/proxies/CompanyProxy'

export const CHECK = 'CHECK'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'

type Context = ActionContext<AuthStoreState, RootStoreState>

const actions = {
  check: ({ commit }: Context) => {
    commit(CHECK)
  },
  login: ({ commit, dispatch, rootGetters }: Context, payload: AuthData) =>
    new Promise((resolve, reject) => {
      new AuthProxy()
        .login(payload)
        .then((response: TokenData) => {
          if (response.access_token) {
            commit(LOGIN, response.access_token)
            dispatch('account/me', null, { root: true }).then(() => {
              const pathData = localStorage.getItem('pathToLoadAfterLogin')
              const pathToLoadAfterLogin = pathData ? JSON.parse(pathData) : null
              const isCustomer = rootGetters['account/isCustomer']

              router
                .push({
                  name:
                    pathToLoadAfterLogin && !(isCustomer && pathToLoadAfterLogin.name.includes('staff'))
                      ? pathToLoadAfterLogin.name
                      : rootGetters['account/homePage'],
                  params: pathToLoadAfterLogin?.params,
                })
                .catch(none)
              resolve(response)
            })
          }
        })
        .catch((e) => {
          if (!e.errors) {
            if (e.response?.data?.error) {
              e.errors = e.response.data.errors
            } else {
              e.errors = e?.message
            }
          }
          reject(e.errors)
        })
    }),
  logout: ({ commit }: Context) => {
    new AuthProxy().logout().finally(() => {
      localStorage.removeItem('pathToLoadAfterLogin')
      commit(LOGOUT)
      router.push({ name: LOGIN_PAGE }).catch((error) => {
        if (error.name !== 'NavigationDuplicated' && !error.message.includes('Navigation cancelled')) {
          console.error(error)
        }
      })
    })
  },
  forgotPassword: (_: Context, payload: ForgotPasswordData) => new AuthProxy().forgotPassword(payload),
  createPassword: (_: Context, payload: CreatePasswordData) =>
    new Promise((resolve, reject) => {
      new AuthProxy()
        .createPassword(payload)
        .then((response) => resolve(response))
        .catch((e) => reject(e.errors))
    }),
  authAsCompany: ({ commit, dispatch, rootGetters }: Context, id: string) => {
    return new Promise((resolve, reject) => {
      new CompanyProxy()
        .authAsCompany(id)
        .then((response: TokenData) => {
          if (response.access_token) {
            commit(LOGIN, response.access_token)
            dispatch('account/me', null, { root: true }).then(() => {
              const pathData = localStorage.getItem('pathToLoadAfterLogin')
              const pathToLoadAfterLogin = pathData ? JSON.parse(pathData) : null
              const isCustomer = rootGetters['account/isCustomer']

              router
                .push({
                  name:
                    pathToLoadAfterLogin && !(isCustomer && pathToLoadAfterLogin.name.includes('staff'))
                      ? pathToLoadAfterLogin.name
                      : rootGetters['account/homePage'],
                  params: pathToLoadAfterLogin?.params,
                })
                .catch(none)
              resolve(response)
            })
          }
        })
        .catch((e) => {
          if (!e.errors) {
            if (e.response?.data?.error) {
              e.errors = e.response.data.errors
            } else {
              e.errors = e?.message
            }
          }
          reject(e.errors)
        })
    })
  },
}

const state: AuthStoreState = {
  authenticated: false,
}

const getters = {}

const mutations = {
  [CHECK](state: AuthStoreState) {
    state.authenticated = !!localStorage.getItem('id_token')
    if (state.authenticated) {
      const token = localStorage.getItem('id_token')
      window.Echo.connector.options.auth.headers.Authorization = `Bearer ${token}`
      Axios.defaults.headers.common.Authorization = `Bearer ${token}`
    }
  },

  [LOGIN](state: AuthStoreState, token: string) {
    state.authenticated = true
    localStorage.setItem('id_token', token)
    window.Echo.connector.options.auth.headers.Authorization = `Bearer ${token}`
    Axios.defaults.headers.common.Authorization = `Bearer ${token}`
  },

  [LOGOUT](state: AuthStoreState) {
    state.authenticated = false
    localStorage.removeItem('id_token')
    Axios.defaults.headers.common.Authorization = ''
  },
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}
