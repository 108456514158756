<template>
  <div class="assignment-stripe">
    <Reference />
    <CompanyInfo />
    <div class="d-flex pr-4 assignment-stripe-status">
      <AssignmentStatus :assignment="assignment" :error-count="errorCount" />
    </div>

    <Downloads />
    <FeaturesCell :assignment="assignment" :staff-icons="false" />
    <LastEdited />
    <PricingLink />
  </div>
</template>

<script lang="ts">
import type { Assignment, StatusType } from '@/types/Models/Assignment'
import { useStore } from 'vuex'
import { pluralize } from '@/services/Helpers'
import { defineComponent, computed, type ComputedRef } from 'vue'
import Reference from '@/views/Components/Assignment/Stripe/Reference.vue'
import Downloads from '@/views/Components/Assignment/Stripe/Downloads.vue'
import LastEdited from '@/views/Components/Assignment/Stripe/LastEdited.vue'
import CompanyInfo from '@/views/Components/Assignment/Stripe/Company.vue'
import PricingLink from '@/views/Components/Assignment/Stripe/PricingLink.vue'
import AssignmentStatus from '@/views/Components/PageParts/AssignmentStatus.vue'
import FeaturesCell from '@/views/Components/Assignment/Cells/Features.vue'

export default defineComponent({
  name: 'AssignmentStripe',
  components: {
    FeaturesCell,
    CompanyInfo,
    PricingLink,
    LastEdited,
    Downloads,
    Reference,
    AssignmentStatus,
  },
  setup() {
    const store = useStore()

    const assignment: ComputedRef<Assignment> = computed(() => store.getters['assignment/assignmentSummary/get'])
    const assignmentStatus: ComputedRef<StatusType> = computed(() => assignment.value.status)
    const errorCount: ComputedRef<number> = computed(
      () => assignment.value.internationalErrorsCount + assignment.value.domesticErrorsCount,
    )

    return {
      assignment,
      assignmentStatus,
      errorCount,
      pluralize,
    }
  },
})
</script>

<style lang="scss">
.border-r {
  border-right: 1px solid #cccccc;
}
.border-l {
  border-left: 1px solid #cccccc;
}
.assignment-stripe-status {
  display: flex;
  align-items: center;
  min-height: 40px;
}

.btn-height-md {
  height: 40px !important;
  line-height: 20px !important;
}
.user-info {
  .color-grey {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #757575;
  }
  .color-black {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    color: #151515;
  }
}

.border-l-none {
  border-left: none;
}

.assignment-function {
  .btn {
    width: auto;
    max-height: 40px;
    min-height: auto;
    padding: 9px 24px;
    font-size: 14px;
    color: #757575;
    border-color: #cccccc;
  }

  .btn:hover {
    color: #fff;
  }
}

.user-info {
  .color-grey {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #757575;
  }
  .color-black {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    color: #151515;
  }
}
</style>
