// Type: Store module
import type { Account } from '@/types/Models/Account'
import type { ActionContext } from 'vuex'
import type { RootStoreState } from '@/types/Store'
import type { AxiosItem } from '@/types/Components/Response'
import AccountProxy from '@/proxies/AccountProxy'
import AccountTransformer from '@/transformers/AccountTransformer'
import { CUSTOMER, STAFF } from '@/const/AccountTypes'
import { CUSTOMER_HOME_PAGE, LOGIN_PAGE, SCAN_PAGE, STAFF_HOME_PAGE } from '@/types/router'
import insp, { install } from 'inspectlet-es'
import { none } from '@/services/Helpers'

export const ME = 'ME'

const state: Account = {
  id: null,
  type: null,
  companyId: null,
  firstName: null,
  lastName: null,
  email: null,
  isScannerOnlyEnabled: false,
}

type Context = ActionContext<Account, RootStoreState>

const actions = {
  me: ({ commit, dispatch }: Context) =>
    new AccountProxy()
      .me()
      .then((response: AxiosItem<Account>) => {
        commit(ME, AccountTransformer.fetch(response.data))
      })
      .catch(() => {
        dispatch('auth/logout', '', { root: true }).then(none)
      }),
}

const mutations = {
  [ME](state: Account, account: Account) {
    const newState = {
      id: account.id,
      companyId: account.companyId,
      type: account.type,
      firstName: account.firstName,
      lastName: account.lastName,
      email: account.email,
      isScannerOnlyEnabled: account.isScannerOnlyEnabled,
    }
    Object.assign(state, newState)

    /**
     * Inspectlet installation
     */
    const devEmails = String(import.meta.env.VITE_DEVELOPERS_EMAILS).split(',')
    if (import.meta.env.VITE_ENV === 'production' && !devEmails.includes(account.email || '')) {
      install(import.meta.env.VITE_APP_INSPECTLET_ID)
      insp([
        'tagSession',
        {
          env: import.meta.env.VITE_ENV,
        },
      ])
      insp(['identify', account.email])
      insp([
        'tagSession',
        {
          userType: account.type,
        },
      ])
    }
  },
}

const getters = {
  homePage(state: Account) {
    switch (state.type) {
      case STAFF:
        return state.isScannerOnlyEnabled ? SCAN_PAGE : STAFF_HOME_PAGE
      case CUSTOMER:
        return CUSTOMER_HOME_PAGE
      default:
        return LOGIN_PAGE
    }
  },
  isStaff(state: Account) {
    return state.type === STAFF
  },
  accountType(state: Account) {
    return state.type
  },
  userId(state: Account) {
    return state.id
  },
  companyId(state: Account) {
    return state.companyId
  },
  isCustomer(state: Account) {
    return state.type === CUSTOMER
  },
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}
