/* ============
 * Account Transformer
 * ============
 *
 * The transformer for the account.
 */

import type { User } from '@/types/Models/User'

export default class UserTransformer {
  /**
   * Method used to transform a fetched account.
   */
  public static fetch(user: User): User {
    return {
      id: user.id,
      companyId: user.companyId,
      type: user.type,
      status: user.status,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      isScannerOnlyEnabled: user.isScannerOnlyEnabled,
    }
  }

  /**
   * Method used to transform a fetched collection.
   */
  static fetchCollection(items: User[]) {
    return items.map((item) => this.fetch(item))
  }
}
